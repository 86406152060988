@charset "UTF-8";
/*
    Blue Oval Theme
    ---------------

    |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ IMPORTANT ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|
    | The Blue Oval theme and webcore should NOT be edited by application teams.  |
    |~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~|

    The Blue Oval theme is based on Web and Mobile Enablement's (WaME)'s webcore, which in turn is based on PrimeNG's
    Prime Designer API.  This is the default theme for projects that do NOT have a need to customize the theming aspect
    of their application.

    Instead, for teams that do have a need to customize their theme, we offer both the App Theme Basic for simple theme
    changes, or the App Theme Advanced for a more modular structure for those teams that have a lot of customizations
    they would like to achieve.
*/
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body .ui-widget {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  font-size: 12px;
  text-decoration: none;
}
body .ui-widget-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
body .ui-widget-content p {
  line-height: 1.5;
  margin: 0;
}
body .ui-corner-all {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-corner-top {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-corner-bottom {
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-corner-left {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-corner-right {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body a {
  color: #102B4E;
  text-decoration: none;
}
body a:hover {
  color: #2861A4;
}
body a:active {
  color: #102B4E;
}
body .ui-helper-reset {
  line-height: normal;
}
body .ui-state-disabled, body .ui-widget:disabled {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
body .pi {
  font-size: 1em;
}
body ::-webkit-input-placeholder {
  color: #4D4D4D;
}
body :-moz-placeholder {
  color: #4D4D4D;
}
body ::-moz-placeholder {
  color: #4D4D4D;
}
body :-ms-input-placeholder {
  color: #4D4D4D;
}

body .ui-autocomplete .ui-autocomplete-input {
  padding: 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container {
  padding: 0.2145em 0.429em;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #2861A4;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  margin: 0;
  padding: 0.2145em 0;
  color: #333333;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  font-size: 12px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #2861A4;
  color: #FFFFFF;
}
body .ui-autocomplete-panel {
  padding: 0;
  border: 1px solid #F4F4F4;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-autocomplete-panel .ui-autocomplete-items {
  padding: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-group {
  padding: 0.429em 0.857em;
  background-color: #6E6E6E;
  color: #333333;
}
body .ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
  width: 2.357em;
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-multiple.ui-autocomplete-dd .ui-autocomplete-multiple-container {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body .ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-inputtext {
  border-right: 0 none;
  width: calc(100% - 2.357em);
}
body p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-datepicker {
  padding: 0.857em;
  min-width: 20em;
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #6E6E6E;
}
body .ui-datepicker:not(.ui-datepicker-inline) {
  border: 1px solid #F4F4F4;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:hover,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:hover {
  color: #2096CD;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-prev:focus,
body .ui-datepicker:not(.ui-state-disabled) .ui-datepicker-header .ui-datepicker-next:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-datepicker:not(.ui-state-disabled) table td a:not(.ui-state-active):not(.ui-state-highlight):hover {
  background-color: #2861A4;
}
body .ui-datepicker:not(.ui-state-disabled) .ui-monthpicker a.ui-monthpicker-month:not(.ui-state-active):hover {
  background-color: #2861A4;
}
body .ui-datepicker .ui-datepicker-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  background-color: #FFFFFF;
  color: #333333;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-prev,
body .ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  cursor: pointer;
  top: 0;
  color: #6E6E6E;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title {
  margin: 0;
  padding: 0;
  line-height: 1;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
  margin-top: -0.35em;
  margin-bottom: 0;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-datepicker table {
  font-size: 12px;
  margin: 0.857em 0 0 0;
}
body .ui-datepicker table th {
  padding: 0.5em;
}
body .ui-datepicker table th.ui-datepicker-weekheader {
  border-right: 1px solid #6E6E6E;
}
body .ui-datepicker table td {
  padding: 0.5em;
}
body .ui-datepicker table td > a, body .ui-datepicker table td > span {
  display: block;
  text-align: center;
  color: #333333;
  padding: 0.5em;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-datepicker table td > a.ui-state-active, body .ui-datepicker table td > span.ui-state-active {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-datepicker table td > a {
  cursor: pointer;
}
body .ui-datepicker table td > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-datepicker table td.ui-datepicker-today > a, body .ui-datepicker table td.ui-datepicker-today > span {
  background-color: #102B4E;
  color: #FFFFFF;
}
body .ui-datepicker table td.ui-datepicker-today > a.ui-state-active, body .ui-datepicker table td.ui-datepicker-today > span.ui-state-active {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-datepicker table td.ui-datepicker-weeknumber {
  border-right: 1px solid #6E6E6E;
}
body .ui-datepicker .ui-datepicker-buttonbar {
  border-top: 1px solid #6E6E6E;
}
body .ui-datepicker .ui-timepicker {
  border: 0 none;
  border-top: 1px solid #4D4D4D;
  padding: 0.857em;
}
body .ui-datepicker .ui-timepicker a {
  color: #333333;
  font-size: 1.286em;
}
body .ui-datepicker .ui-timepicker a:hover {
  color: #2096CD;
}
body .ui-datepicker .ui-timepicker span {
  font-size: 1.286em;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month {
  color: #333333;
}
body .ui-datepicker .ui-monthpicker .ui-monthpicker-month.ui-state-active {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-datepicker.ui-datepicker-timeonly {
  padding: 0;
}
body .ui-datepicker.ui-datepicker-timeonly .ui-timepicker {
  border-top: 0 none;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group {
  border-right: 1px solid #6E6E6E;
  padding-right: 0.857em;
  padding-left: 0.857em;
  padding-top: 0;
  padding-bottom: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:first-child {
  padding-left: 0;
}
body .ui-datepicker.ui-datepicker-multiple-month .ui-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext {
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:hover:not(.ui-state-error), body .ui-calendar.ui-calendar-w-btn .ui-inputtext:enabled:focus:not(.ui-state-error) {
  border-right: 0 none;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  width: 2.357em;
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
body .ui-fluid .ui-calendar.ui-calendar-w-btn input.ui-inputtext {
  width: calc(100% - 2.357em);
}
body p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-chkbox {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 20px;
  height: 20px;
}
body .ui-chkbox .ui-chkbox-box {
  border: 1px solid #6E6E6E;
  background-color: #FFFFFF;
  width: 17px;
  height: 17px;
  text-align: center;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
body .ui-chkbox .ui-chkbox-box:not(.ui-state-disabled):hover {
  border-color: #2861A4;
}
body .ui-chkbox .ui-chkbox-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active {
  border-color: #2861A4;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-chkbox .ui-chkbox-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #2861A4;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-chkbox .ui-chkbox-box .ui-chkbox-icon {
  overflow: hidden;
  position: relative;
  font-size: 16px;
}
body .ui-chkbox-label {
  margin: 0 0 0 0.5em;
  font-size: 10px;
}
body p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box {
  border: 1px solid #D62D0A;
}
body .ui-chips > ul.ui-inputtext {
  padding: 0.2145em 0.429em;
  display: inline-block;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled):hover {
  border-color: #2861A4;
}
body .ui-chips > ul.ui-inputtext:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token {
  padding: 0.2145em 0;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  font-size: 12px;
  padding: 0;
  margin: 0;
  color: #333333;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:hover {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-input-token input:focus {
  border: 0 none;
}
body .ui-chips > ul.ui-inputtext .ui-chips-token {
  font-size: 12px;
  padding: 0.2145em 0.429em;
  margin: 0 0.286em 0 0;
  background: #2861A4;
  color: #FFFFFF;
}
body p-chips.ng-dirty.ng-invalid > .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-dropdown {
  background: #FFFFFF;
  border: 1px solid #6E6E6E;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #2861A4;
}
body .ui-dropdown:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-dropdown .ui-dropdown-label {
  padding-right: 2em;
}
body .ui-dropdown .ui-dropdown-label .ui-placeholder {
  color: #4D4D4D;
}
body .ui-dropdown .ui-dropdown-trigger {
  background-color: #FFFFFF;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #4D4D4D;
}
body .ui-dropdown .ui-dropdown-clear-icon {
  color: #4D4D4D;
}
body .ui-dropdown.ui-dropdown-clearable .ui-dropdown-label {
  padding-right: 4em;
}
body .ui-dropdown-panel {
  padding: 0;
  border: 1px solid #F4F4F4;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dropdown-panel .ui-dropdown-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #6E6E6E;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-dropdown-panel .ui-dropdown-filter-container .ui-dropdown-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  right: 1.357em;
  color: #2096CD;
}
body .ui-dropdown-panel .ui-dropdown-items {
  padding: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item.ui-state-highlight, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group.ui-state-highlight {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover, body .ui-dropdown-panel .ui-dropdown-items .ui-dropdown-item-group:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #FFFFFF;
  background-color: #2861A4;
}
body p-dropdown.ng-dirty.ng-invalid > .ui-dropdown {
  border: 1px solid #D62D0A;
}
body .ui-editor-container .ui-editor-toolbar {
  border: 1px solid #102B4E;
  background-color: #102B4E;
}
body .ui-editor-container .ui-editor-content {
  border: 1px solid #102B4E;
}
body .ui-editor-container .ui-editor-content .ql-editor {
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-editor-container .ql-picker.ql-expanded .ql-picker-label {
  color: #FFFFFF;
}
body .ui-editor-container .ql-stroke {
  stroke: #FFFFFF;
}
body .ui-editor-container .ql-picker-label {
  color: #FFFFFF;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover,
body .ui-editor-container .ql-snow .ql-toolbar button:hover,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #2861A4;
}
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
body .ui-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
body .ui-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
body .ui-editor-container .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: #2861A4;
}
body .ui-inputgroup .ui-inputgroup-addon {
  border-color: #102B4E;
  background-color: #2861A4;
  color: #FFFFFF;
  padding: 0.429em;
  min-width: 2em;
}
body .ui-inputgroup .ui-inputgroup-addon:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-checkbox .ui-chkbox {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -10px;
  margin-top: -10px;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton {
  position: relative;
}
body .ui-inputgroup .ui-inputgroup-addon.ui-inputgroup-addon-radiobutton .ui-radiobutton {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -6px;
  margin-top: -6px;
}
body .ui-inputgroup .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-inputgroup .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-fluid .ui-inputgroup .ui-button {
  width: auto;
}
body .ui-fluid .ui-inputgroup .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body .ui-inputswitch {
  width: 3em;
  height: 1.75em;
}
body .ui-inputswitch .ui-inputswitch-slider {
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  border-radius: 30px;
  background: #8C8C8C;
}
body .ui-inputswitch .ui-inputswitch-slider:before {
  background-color: #FFFFFF;
  height: 1.25em;
  width: 1.25em;
  left: 0.25em;
  bottom: 0.25em;
  border-radius: 50%;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider:before {
  -webkit-transform: translateX(1.25em);
  -ms-transform: translateX(1.25em);
  transform: translateX(1.25em);
}
body .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #6E6E6E;
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #6E6E6E;
}
body .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #2096CD;
}
body .ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #2861A4;
}
body .ui-inputswitch.ui-inputswitch-checked.ui-inputswitch-focus .ui-inputswitch-slider {
  background: #102B4E;
}
body p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch {
  border: 1px solid #D62D0A;
}
body .ui-inputtext {
  font-size: 12px;
  color: #333333;
  background: #FFFFFF;
  padding: 0.429em;
  border: 1px solid #6E6E6E;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
body .ui-inputtext.ng-dirty.ng-invalid {
  border: 1px solid #D62D0A;
}
body .ui-inputtext:enabled:hover:not(.ui-state-error) {
  border-color: #2861A4;
}
body .ui-inputtext:enabled:focus:not(.ui-state-error) {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body p-inputmask.ng-dirty.ng-invalid > .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-listbox {
  padding: 0;
  min-width: 12em;
  background: #FFFFFF;
  border: 1px solid #6E6E6E;
}
body .ui-listbox .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #6E6E6E;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container {
  width: calc(100% - (0.857em + 0.857em + 0.5em));
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container input {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-listbox .ui-listbox-header .ui-listbox-filter-container .ui-listbox-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.5em;
  color: #2096CD;
}
body .ui-listbox .ui-listbox-list {
  background-color: #FFFFFF;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-listbox .ui-listbox-list .ui-listbox-item label {
  display: inline-block;
  vertical-align: middle;
}
body .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border: 1px solid #6E6E6E;
}
body .ui-listbox .ui-listbox-footer {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-top: 1px solid #6E6E6E;
  color: #333333;
  background-color: #FFFFFF;
}
body p-listbox.ng-dirty.ng-invalid .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-multiselect {
  background: #FFFFFF;
  border: 1px solid #6E6E6E;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #2861A4;
}
body .ui-multiselect:not(.ui-state-disabled).ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-multiselect .ui-multiselect-label {
  padding: 0.429em;
  padding-right: 2em;
  color: #333333;
}
body .ui-multiselect .ui-multiselect-label .ui-placeholder {
  color: #4D4D4D;
}
body .ui-multiselect .ui-multiselect-trigger {
  background-color: #FFFFFF;
  width: 2em;
  line-height: 2em;
  text-align: center;
  padding: 0;
  color: #4D4D4D;
}
body .ui-multiselect-panel {
  padding: 0;
  border: 1px solid #F4F4F4;
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-multiselect-panel .ui-multiselect-header {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border-bottom: 1px solid #6E6E6E;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container {
  float: none;
  width: 70%;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-inputtext {
  padding: 0.429em;
  padding-right: 2em;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-filter-container .ui-multiselect-filter-icon {
  color: #2096CD;
  top: 50%;
  margin-top: -0.5em;
  right: 0.5em;
  left: auto;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-chkbox {
  margin-right: 0.5em;
  float: none;
  vertical-align: middle;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close {
  color: #4D4D4D;
  top: 50%;
  margin-top: -0.5em;
  line-height: 1;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:hover {
  color: #333333;
}
body .ui-multiselect-panel .ui-multiselect-header .ui-multiselect-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-multiselect-panel .ui-multiselect-items {
  padding: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item {
  margin: 0;
  padding: 0.429em 0.857em;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-highlight {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  color: #FFFFFF;
  background-color: #2861A4;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box {
  cursor: auto;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box:hover {
  border: 1px solid #6E6E6E;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item.ui-state-disabled .ui-chkbox-box.ui-state-active:hover {
  border-color: #2861A4;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item .ui-chkbox {
  position: static;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
body .ui-multiselect-panel .ui-multiselect-items .ui-multiselect-item label {
  display: inline-block;
  vertical-align: middle;
}
body p-multiselect.ng-dirty.ng-invalid > .ui-multiselect {
  border: 1px solid #D62D0A;
}
body .ui-password-panel {
  padding: 12px;
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #F4F4F4;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-radiobutton {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  width: 12px;
  height: 12px;
}
body .ui-radiobutton .ui-radiobutton-box {
  border: 1px solid #6E6E6E;
  background-color: #FFFFFF;
  width: 12px;
  height: 12px;
  width: 12px;
  height: 12px;
  text-align: center;
  position: relative;
  -moz-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #2861A4;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
  border-color: #2096CD;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active {
  border-color: #102B4E;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active .ui-radiobutton-icon {
  background-color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box.ui-state-active:not(.ui-state-disabled):hover {
  border-color: #2861A4;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon {
  background: transparent;
  width: 6px;
  height: 6px;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3px;
  margin-top: -3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
body .ui-radiobutton .ui-radiobutton-box .ui-radiobutton-icon:before {
  display: none;
}
body .ui-radiobutton-label {
  margin: 0 0 0 0.5em;
  font-weight: lighter;
  font-size: 13px;
}
body p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box {
  border: 1px solid #D62D0A;
}
body .ui-rating a {
  text-align: center;
  display: inline-block;
  color: #333333;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  line-height: 1;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-rating a.ui-rating-cancel {
  color: #37A9E8;
}
body .ui-rating a .ui-rating-icon {
  font-size: 20px;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:hover {
  color: #2096CD;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a.ui-rating-cancel:hover {
  color: #2096CD;
}
body .ui-rating:not(.ui-state-disabled):not(.ui-rating-readonly) a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-selectbutton .ui-button {
  background-color: #102B4E;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  overflow: hidden;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-selectbutton .ui-button .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
  z-index: 1;
}
body .ui-selectbutton .ui-button.ui-state-active {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-selectbutton .ui-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .ui-selectbutton .ui-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body p-selectbutton.ng-dirty.ng-invalid .ui-button {
  border: 1px solid #D62D0A;
}
body .ui-slider {
  background-color: #8C8C8C;
  border: 0 none;
}
body .ui-slider.ui-slider-horizontal {
  height: 0.286em;
}
body .ui-slider.ui-slider-horizontal .ui-slider-handle {
  top: 50%;
  margin-top: -0.5715em;
}
body .ui-slider.ui-slider-vertical {
  width: 0.286em;
}
body .ui-slider.ui-slider-vertical .ui-slider-handle {
  left: 50%;
  margin-left: -0.5715em;
}
body .ui-slider .ui-slider-handle {
  height: 1.143em;
  width: 1.143em;
  background-color: #FFFFFF;
  border: 2px solid #4D4D4D;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border-radius: 100%;
  -moz-transition: border-color 0.2s;
  -o-transition: border-color 0.2s;
  -webkit-transition: border-color 0.2s;
  transition: border-color 0.2s;
}
body .ui-slider .ui-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-slider .ui-slider-range {
  background-color: #2861A4;
}
body .ui-slider:not(.ui-state-disabled) .ui-slider-handle:hover {
  background-color: 2px solid #4D4D4D;
  border: 2px solid #2096CD;
}
body .ui-slider.ui-slider-animate .ui-slider-handle {
  -moz-transition: border-color 0.2s, left 0.2s;
  -o-transition: border-color 0.2s, left 0.2s;
  -webkit-transition: border-color 0.2s, left 0.2s;
  transition: border-color 0.2s, left 0.2s;
}
body .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-spinner .ui-spinner-button.ui-spinner-up {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button.ui-spinner-down {
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
}
body .ui-fluid .ui-spinner .ui-spinner-input {
  padding-right: 2.429em;
}
body .ui-fluid .ui-spinner .ui-spinner-button {
  width: 2em;
}
body .ui-fluid .ui-spinner .ui-spinner-button .ui-spinner-button-icon {
  left: 50%;
}
body p-spinner.ng-dirty.ng-invalid > .ui-inputtext {
  border: 1px solid #D62D0A;
}
body .ui-togglebutton {
  background-color: #102B4E;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-togglebutton .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .ui-togglebutton.ui-state-active {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active .ui-button-icon-left {
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active:hover {
  background-color: #2861A4;
  border-color: #2861A4;
  color: #FFFFFF;
}
body .ui-togglebutton.ui-state-active:hover .ui-button-icon-left {
  color: #FFFFFF;
}
body p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border: 1px solid #D62D0A;
}
body .ui-button {
  margin: 0;
  color: #FFFFFF;
  background-color: #102B4E;
  border: 1px solid #102B4E;
  font-size: 12px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-button:enabled:hover {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .ui-button:enabled:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .ui-button:enabled:active {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .ui-button.ui-button-text-only .ui-button-text {
  padding: 0.429em 1em;
}
body .ui-button.ui-button-text-icon-left .ui-button-text {
  padding: 0.429em 1em 0.429em 2em;
}
body .ui-button.ui-button-text-icon-right .ui-button-text {
  padding: 0.429em 2em 0.429em 1em;
}
body .ui-button.ui-button-icon-only {
  width: 2.357em;
}
body .ui-button.ui-button-icon-only .ui-button-text {
  padding: 0.429em;
}
body .ui-button.ui-button-raised {
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
body .ui-button.ui-button-rounded {
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
body .ui-fluid .ui-button-icon-only {
  width: 2.357em;
}
@media (max-width: 640px) {
  body .ui-buttonset:not(.ui-splitbutton) .ui-button {
    margin-bottom: 1px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
  }
}
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-menu {
  min-width: 100%;
}
body .ui-button.ui-state-default.ui-button-secondary, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default {
  color: #333333;
  background-color: #F4F4F4;
  border: 1px solid #6E6E6E;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:hover, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:hover {
  background-color: #e6e6e6;
  color: #333333;
  border-color: #4D4D4D;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:active, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:active {
  background-color: #FFFFFF;
  color: #333333;
  border-color: #4D4D4D;
}
body .ui-button.ui-state-default.ui-button-info, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #2096CD;
  border: 1px solid #2861A4;
}
body .ui-button.ui-state-default.ui-button-info:enabled:hover, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:hover {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2096CD;
}
body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .ui-button.ui-state-default.ui-button-info:enabled:active, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:active {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2096CD;
}
body .ui-button.ui-state-default.ui-button-success, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #008200;
  border: 1px solid #008200;
}
body .ui-button.ui-state-default.ui-button-success:enabled:hover, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:hover {
  background-color: #004f00;
  color: #FFFFFF;
  border-color: #008200;
}
body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.1em #36ff36;
  -moz-box-shadow: 0 0 0 0.1em #36ff36;
  box-shadow: 0 0 0 0.1em #36ff36;
}
body .ui-button.ui-state-default.ui-button-success:enabled:active, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:active {
  background-color: #001c00;
  color: #FFFFFF;
  border-color: #008200;
}
body .ui-button.ui-state-default.ui-button-warning, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #DA7029;
  border: 1px solid #DA7029;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:hover, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:hover {
  background-color: #9c4f1b;
  color: #FFFFFF;
  border-color: #DA7029;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.1em #f4d6c1;
  -moz-box-shadow: 0 0 0 0.1em #f4d6c1;
  box-shadow: 0 0 0 0.1em #f4d6c1;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:active, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:active {
  background-color: #5a2e10;
  color: #FFFFFF;
  border-color: #DA7029;
}
body .ui-button.ui-state-default.ui-button-danger, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default {
  color: #FFFFFF;
  background-color: #D62D0A;
  border: 1px solid #D62D0A;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:hover, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:hover {
  background-color: #a52308;
  color: #FFFFFF;
  border-color: #D62D0A;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  -webkit-box-shadow: 0 0 0 0.1em #faa998;
  -moz-box-shadow: 0 0 0 0.1em #faa998;
  box-shadow: 0 0 0 0.1em #faa998;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:active, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:active {
  background-color: #751905;
  color: #FFFFFF;
  border-color: #D62D0A;
}
body .ui-splitbutton.ui-buttonset .ui-button {
  border: 1px solid transparent;
}
body .ui-splitbutton.ui-buttonset .ui-menu {
  min-width: 100%;
}
body .ui-carousel .ui-carousel-content .ui-carousel-prev,
body .ui-carousel .ui-carousel-content .ui-carousel-next {
  background-color: #102B4E;
  border: 1px solid #102B4E;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin: 0.2em;
  color: #FFFFFF;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-carousel .ui-carousel-content .ui-carousel-prev:not(.ui-state-disabled):hover,
body .ui-carousel .ui-carousel-content .ui-carousel-next:not(.ui-state-disabled):hover {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item > .ui-button {
  border-color: transparent;
  background-color: transparent;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon {
  width: 20px;
  height: 6px;
  background-color: #b2c1cd;
  margin: 0 0.2em;
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item .ui-carousel-dot-icon::before {
  content: " ";
}
body .ui-carousel .ui-carousel-dots-container .ui-carousel-dot-item.ui-state-highlight .ui-carousel-dot-icon {
  background-color: #2096CD;
}
body .ui-dataview .ui-dataview-header {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-dataview .ui-dataview-content {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-dataview .ui-dataview-footer {
  background-color: #F4F4F4;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-dataview .ui-dataview-layout-options .ui-button {
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-dataview .ui-dataview-layout-options .ui-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
  z-index: 1;
}
body .fc th {
  background-color: #102B4E;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  padding: 0.571em 1em;
}
body .fc td.ui-widget-content {
  background-color: #FFFFFF;
  border: 1px solid #102B4E;
  color: #333333;
}
body .fc td.fc-head-container {
  border: 1px solid #102B4E;
}
body .fc .fc-row {
  border-right: 1px solid #102B4E;
}
body .fc .fc-event {
  background-color: #2861A4;
  border: 1px solid #2861A4;
  color: #FFFFFF;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .fc .fc-event:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .fc .fc-toolbar .fc-button {
  color: #FFFFFF;
  background-color: #102B4E;
  border: 1px solid #102B4E;
  font-size: 12px;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .fc .fc-toolbar .fc-button:enabled:hover {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "";
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "PrimeIcons" !important;
  text-indent: 0;
}
body .fc .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "";
}
body .fc .fc-toolbar .fc-button:focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .fc .fc-toolbar .fc-button-group .fc-button {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .fc .fc-toolbar .fc-button-group .fc-button:first-child {
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-bottomleft: 3px;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .fc .fc-toolbar .fc-button-group .fc-button:last-child {
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomright: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
body .fc .fc-divider {
  background-color: #102B4E;
  border: 1px solid #102B4E;
}
body .ui-fluid .fc .fc-toolbar .fc-button {
  width: auto;
}
body .ui-orderlist .ui-orderlist-controls {
  padding: 0.571em 1em;
}
body .ui-orderlist .ui-orderlist-controls button {
  font-size: 14px;
}
body .ui-orderlist .ui-orderlist-caption {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-orderlist .ui-orderlist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #102B4E;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-orderlist .ui-orderlist-filter-container input.ui-inputtext {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-orderlist .ui-orderlist-filter-container .ui-orderlist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2096CD;
}
body .ui-orderlist .ui-orderlist-list {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-orderlist .ui-orderlist-list .ui-orderlist-droppoint-highlight {
  background-color: #2861A4;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-organizationchart .ui-organizationchart-node-content.ui-state-highlight .ui-node-toggler i {
  color: #0f243e;
}
body .ui-organizationchart .ui-organizationchart-line-down {
  background-color: #F4F4F4;
}
body .ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid #102B4E;
  border-color: #F4F4F4;
}
body .ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid #102B4E;
  border-color: #F4F4F4;
}
body .ui-organizationchart .ui-organizationchart-node-content {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  bottom: -0.7em;
  margin-left: -0.46em;
  color: #4D4D4D;
  overflow: hidden;
  line-height: 1;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-paginator {
  background-color: #FFFFFF;
  border: none;
  padding: 0;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  color: #333333;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
  background-color: #102B4E;
  color: #FFFFFF;
}
body .ui-paginator .ui-paginator-first:focus,
body .ui-paginator .ui-paginator-prev:focus,
body .ui-paginator .ui-paginator-next:focus,
body .ui-paginator .ui-paginator-last:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-paginator .ui-paginator-current {
  color: #333333;
  height: 2.286em;
  min-width: 2.286em;
  line-height: 2.286em;
}
body .ui-paginator .ui-dropdown {
  border: 0 none;
}
body .ui-paginator .ui-dropdown .ui-dropdown-trigger, body .ui-paginator .ui-dropdown .ui-dropdown-label {
  color: #333333;
}
body .ui-paginator .ui-dropdown:hover .ui-dropdown-trigger, body .ui-paginator .ui-dropdown:hover .ui-dropdown-label {
  color: #FFFFFF;
}
body .ui-paginator .ui-paginator-first:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-prev:before {
  position: relative;
}
body .ui-paginator .ui-paginator-next:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-last:before {
  position: relative;
  top: 1px;
}
body .ui-paginator .ui-paginator-pages {
  vertical-align: top;
  display: inline-block;
  padding: 0;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  color: #333333;
  height: 2.286em;
  min-width: 2.286em;
  border: 0 none;
  line-height: 2.286em;
  padding: 0;
  margin: 0;
  vertical-align: top;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  background-color: #102B4E;
  color: #FFFFFF;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-paginator .ui-dropdown {
  margin-left: 0.5em;
  height: 2.286em;
  min-width: auto;
}
body .ui-picklist .ui-picklist-buttons button {
  font-size: 14px;
}
body .ui-picklist .ui-picklist-caption {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
}
body .ui-picklist .ui-picklist-filter-container {
  padding: 0.429em 0.857em 0.429em 0.857em;
  border: 1px solid #102B4E;
  color: #333333;
  background-color: #FFFFFF;
  margin: 0;
  border-bottom: 0 none;
}
body .ui-picklist .ui-picklist-filter-container input.ui-picklist-filter {
  width: 100%;
  padding-right: 2em;
  text-indent: 0;
}
body .ui-picklist .ui-picklist-filter-container .ui-picklist-filter-icon {
  top: 50%;
  margin-top: -0.5em;
  left: auto;
  right: 1.357em;
  color: #2096CD;
}
body .ui-picklist .ui-picklist-buttons {
  padding: 0.571em 1em;
}
body .ui-picklist .ui-picklist-list {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item {
  padding: 0.429em 0.857em;
  margin: 0;
  border: 0 none;
  color: #333333;
  background-color: transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-picklist .ui-picklist-list .ui-picklist-item:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-picklist .ui-picklist-list .ui-picklist-droppoint-highlight {
  background-color: #2861A4;
}
@media (max-width: 40em) {
  body .ui-picklist.ui-picklist-responsive .ui-picklist-buttons {
    padding: 0.571em 1em;
  }
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-table .ui-table-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-table .ui-table-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #6E6E6E;
  font-weight: 700;
  color: #FFFFFF;
  background-color: #102B4E;
}
body .ui-table .ui-table-tbody > tr > td {
  padding: none;
}
body .ui-table .ui-table-tfoot > tr > td {
  padding: none;
  border: 1px solid #6E6E6E;
  font-weight: 700;
  color: #FFFFFF;
  background-color: #102B4E;
}
body .ui-table .ui-sortable-column {
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-table .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #F4F4F4;
}
body .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #F4F4F4;
}
body .ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-table .ui-editable-column input {
  font-size: 12px;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
}
body .ui-table .ui-editable-column input:focus {
  outline: 1px solid #2096CD;
  outline-offset: 2px;
}
body .ui-table .ui-table-tbody > tr {
  background-color: #FFFFFF;
  color: #333333;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-table .ui-table-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #6E6E6E;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr.ui-state-highlight a {
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
  -webkit-box-shadow: inset 0 2px 0 0 #2861A4;
  -moz-box-shadow: inset 0 2px 0 0 #2861A4;
  box-shadow: inset 0 2px 0 0 #2861A4;
}
body .ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  -webkit-box-shadow: inset 0 -2px 0 0 #2861A4;
  -moz-box-shadow: inset 0 -2px 0 0 #2861A4;
  box-shadow: inset 0 -2px 0 0 #2861A4;
}
body .ui-table .ui-table-tbody > tr:focus {
  outline: none;
}
body .ui-table .ui-table-tbody > tr:focus + tr > td {
  box-shadow: 0 -0.2em 0 0 #addcf2;
}
body .ui-table .ui-table-tbody > tr:focus > td {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2;
}
body .ui-table .ui-table-tbody > tr:focus > td:first-child {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2, -0.1em -0.1em 0 0.1em #addcf2;
}
body .ui-table .ui-table-tbody > tr:focus > td:last-child {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2, 0.1em -0.1em 0 0.1em #addcf2;
}
body .ui-table .ui-table-tbody > tr:nth-child(even) {
  background-color: #F1F1F1;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-table .ui-table-tbody > tr:nth-child(even).ui-state-highlight a {
  color: #FFFFFF;
}
body .ui-table.ui-table-hoverable-rows .ui-table-tbody > tr.ui-selectable-row:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #6E6E6E;
  color: #FFFFFF;
}
body .ui-table .ui-column-resizer-helper {
  background-color: #2096CD;
}
body .ui-table .ui-table-scrollable-wrapper {
  overflow: auto !important;
}
body .ui-table .ui-table-scrollable-header, body .ui-table .ui-table-scrollable-body, body .ui-table .ui-table-scrollable-footer {
  overflow: auto !important;
}
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-paginator-top {
    border-bottom: none;
  }
  body .ui-table.ui-table-responsive .ui-paginator-bottom {
    border-top: none;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr td {
    border: 0 none;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr:focus + tr > td {
    box-shadow: none;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr:focus > td {
    box-shadow: none;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr:focus > td:first-child {
    box-shadow: none;
  }
  body .ui-table.ui-table-responsive .ui-table-tbody > tr:focus > td:last-child {
    box-shadow: none;
  }
}
body .ui-tree {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-tree .ui-tree-container {
  padding: 0.286em;
  margin: 0;
}
body .ui-tree .ui-tree-container .ui-treenode {
  padding: 0.143em 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content {
  padding: 0;
  border: 1px solid transparent;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-tree-toggler {
  vertical-align: middle;
  display: inline-block;
  float: none;
  margin: 0 0.143em 0 0;
  color: #4D4D4D;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-icon {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0.143em 0 0;
  color: #4D4D4D;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label {
  margin: 0;
  vertical-align: middle;
  display: inline-block;
  padding: 0.286em;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-treenode-label.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox {
  margin: 0 0.5em 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content .ui-chkbox .ui-chkbox-icon {
  margin: 1px 0 0 0;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content > span {
  line-height: inherit;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content.ui-treenode-dragover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-tree .ui-tree-container .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-tree .ui-tree-container .ui-treenode-droppoint.ui-treenode-droppoint-active {
  background-color: #2861A4;
}
body .ui-tree.ui-tree-horizontal {
  padding-left: 0;
  padding-right: 0;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
  -moz-transition: background-color 0.2s, box-shadow 0.2s;
  -o-transition: background-color 0.2s, box-shadow 0.2s;
  -webkit-transition: background-color 0.2s, box-shadow 0.2s;
  transition: background-color 0.2s, box-shadow 0.2s;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-chkbox .ui-icon {
  color: #2096CD;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-tree.ui-tree-horizontal .ui-treenode .ui-treenode-content:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter {
  width: 100%;
  padding-right: 2em;
}
body .ui-tree .ui-tree-filter-container .ui-tree-filter-icon {
  top: 50%;
  left: auto;
  margin-top: -0.5em;
  right: 0.8em;
  color: #2096CD;
}
body .ui-treetable .ui-treetable-caption,
body .ui-treetable .ui-treetable-summary {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  text-align: center;
}
body .ui-treetable .ui-treetable-caption {
  border-bottom: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-summary {
  border-top: 0 none;
  font-weight: 700;
}
body .ui-treetable .ui-treetable-thead > tr > th {
  padding: 0.571em 0.857em;
  border: 1px solid #6E6E6E;
  font-weight: 700;
  color: #FFFFFF;
  background-color: #102B4E;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  padding: none;
}
body .ui-treetable .ui-treetable-tfoot > tr > td {
  padding: none;
  border: 1px solid #6E6E6E;
  font-weight: 700;
  color: #FFFFFF;
  background-color: #102B4E;
}
body .ui-treetable .ui-sortable-column {
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-treetable .ui-sortable-column:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #2861A4;
  color: #F4F4F4;
}
body .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
  color: #F4F4F4;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
  color: #FFFFFF;
}
body .ui-treetable .ui-editable-column input {
  font-size: 12px;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
}
body .ui-treetable .ui-editable-column input:focus {
  outline: 1px solid #2096CD;
  outline-offset: 2px;
}
body .ui-treetable .ui-treetable-tbody > tr {
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-treetable .ui-treetable-tbody > tr > td {
  background-color: inherit;
  border: 1px solid #6E6E6E;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-toggler {
  color: #4D4D4D;
  vertical-align: middle;
}
body .ui-treetable .ui-treetable-tbody > tr > td .ui-treetable-chkbox {
  vertical-align: middle;
  margin-right: 0.5em;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td {
  background-color: inherit;
  border: 1px solid #6E6E6E;
}
body .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight > td .ui-treetable-toggler {
  color: #FFFFFF;
}
body .ui-treetable .ui-treetable-tbody > tr:focus {
  outline: none;
}
body .ui-treetable .ui-treetable-tbody > tr:focus + tr > td {
  box-shadow: 0 -0.2em 0 0 #addcf2;
}
body .ui-treetable .ui-treetable-tbody > tr:focus > td {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2;
}
body .ui-treetable .ui-treetable-tbody > tr:focus > td:first-child {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2, -0.1em -0.1em 0 0.1em #addcf2;
}
body .ui-treetable .ui-treetable-tbody > tr:focus > td:last-child {
  box-shadow: 0 -0.2em 0 0 #addcf2, 0 0.2em 0 0 #addcf2, 0.1em -0.1em 0 0.1em #addcf2;
}
body .ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #6E6E6E;
  color: #FFFFFF;
}
body .ui-treetable .ui-column-resizer-helper {
  background-color: #2096CD;
}
body .ui-virtualscroller .ui-virtualscroller-header {
  background-color: #102B4E;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  font-weight: 400;
  border-bottom: 0 none;
}
body .ui-virtualscroller .ui-virtualscroller-content {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-virtualscroller .ui-virtualscroller-content .ui-virtualscroller-list li {
  border-bottom: 1px solid #102B4E;
}
body .ui-virtualscroller .ui-virtualscroller-footer {
  background-color: #F4F4F4;
  color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-accordion .ui-accordion-header {
  margin-bottom: 2px;
}
body .ui-accordion .ui-accordion-header a {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  background-color: #102B4E;
  color: #FFFFFF;
  font-weight: 400;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-accordion .ui-accordion-header a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
  background-color: #2861A4;
  border: 1px solid #6E6E6E;
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a {
  background-color: #2861A4;
  border: 1px solid #4D4D4D;
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a {
  border: 1px solid #4D4D4D;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-header:not(.ui-state-disabled).ui-state-active:hover a .ui-accordion-toggle-icon {
  color: #FFFFFF;
}
body .ui-accordion .ui-accordion-content {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
}
body .ui-card {
  background-color: #FFFFFF;
  color: #333333;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
}
body .ui-fieldset {
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
}
body .ui-fieldset .ui-fieldset-legend a {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  background-color: #102B4E;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  text-decoration: none;
  font-size: 12px;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-toggler {
  float: none;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.5em;
  color: #FFFFFF;
}
body .ui-fieldset .ui-fieldset-legend a .ui-fieldset-legend-text {
  padding: 0;
}
body .ui-fieldset .ui-fieldset-legend a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover {
  background-color: #2861A4;
  border: 1px solid #6E6E6E;
  color: #FFFFFF;
}
body .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend a:hover .ui-fieldset-toggler {
  color: #FFFFFF;
}
body .ui-fieldset .ui-fieldset-content {
  padding: 0;
}
body .ui-panel {
  padding: 0;
  border: 0 none;
}
body .ui-panel .ui-panel-titlebar {
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
  background-color: #102B4E;
  color: #FFFFFF;
  -moz-border-radius-topleft: 3px;
  -webkit-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -moz-border-radius-topright: 3px;
  -webkit-border-top-right-radius: 3px;
  border-top-right-radius: 3px;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-panel .ui-panel-titlebar .ui-panel-title {
  vertical-align: middle;
  font-weight: 400;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon {
  margin: 0;
  position: relative;
  font-size: 12px;
  color: #FFFFFF;
  border: 1px solid transparent;
  line-height: 1;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #FFFFFF;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-panel .ui-panel-content {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
  border-top: 0 none;
}
body .ui-panel .ui-panel-footer {
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  background-color: #F4F4F4;
  color: #FFFFFF;
  border-top: 0 none;
  margin: 0;
}
body .ui-tabview.ui-tabview-top, body .ui-tabview.ui-tabview-bottom, body .ui-tabview.ui-tabview-left, body .ui-tabview.ui-tabview-right {
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav, body .ui-tabview.ui-tabview-left .ui-tabview-nav, body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  padding: 0;
  background: transparent;
  border: 0 none;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li, body .ui-tabview.ui-tabview-left .ui-tabview-nav li, body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  border: 1px solid #102B4E;
  background-color: #102B4E;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a {
  float: none;
  display: inline-block;
  color: #FFFFFF;
  padding: 0.571em 1em;
  font-weight: 400;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-left-icon {
  margin-right: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li a .ui-tabview-right-icon {
  margin-left: 0.5em;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close {
  color: #FFFFFF;
  margin: 0 0.5em 0 0;
  vertical-align: middle;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li .ui-tabview-close:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li .ui-tabview-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #2861A4;
  border: 1px solid #6E6E6E;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover .ui-tabview-close {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background-color: #2861A4;
  border: 1px solid #4D4D4D;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active a {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active .ui-tabview-close, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active .ui-tabview-close {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  border: 1px solid #4D4D4D;
  background-color: #2861A4;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-left-icon, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover a .ui-tabview-right-icon {
  color: #FFFFFF;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-tabview-selected a, body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-tabview-selected a {
  cursor: pointer;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-left .ui-tabview-nav li:focus, body .ui-tabview.ui-tabview-right .ui-tabview-nav li:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav {
  margin-bottom: -1px;
}
body .ui-tabview.ui-tabview-top .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav {
  margin-top: -1px;
}
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li {
  margin-right: 2px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav {
  margin-right: -px;
}
body .ui-tabview.ui-tabview-left .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav {
  margin-right: -1px;
}
body .ui-tabview.ui-tabview-right .ui-tabview-nav li {
  margin-bottom: 2px;
}
body .ui-tabview .ui-tabview-panels {
  background-color: #FFFFFF;
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  color: #333333;
}
body .ui-tabview .ui-tabview-panels .ui-tabview-panel {
  padding: 0;
}
body .ui-toolbar {
  background-color: #102B4E;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
}
body .ui-toolbar button {
  vertical-align: middle;
}
body .ui-toolbar .ui-toolbar-separator {
  vertical-align: middle;
  color: #FFFFFF;
  margin: 0 0.5em;
}
body .ui-dialog {
  padding: 0;
  margin-left: 200px;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-dialog .ui-dialog-titlebar {
  border: 1px solid #102B4E;
  background-color: #102B4E;
  color: #FFFFFF;
  padding: 1em;
  font-weight: 400;
  border-bottom: 0 none;
  font-size: 20px;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-title {
  margin: 0;
  float: none;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon {
  color: #FFFFFF;
  border: 0 none;
  padding: 0;
  margin-left: 0.5em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon span {
  width: auto;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:hover {
  color: #FFFFFF;
}
body .ui-dialog .ui-dialog-titlebar .ui-dialog-titlebar-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-dialog .ui-dialog-content {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #F4F4F4;
  padding: 0.571em 1em;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
}
body .ui-dialog .ui-dialog-footer {
  border: 1px solid #102B4E;
  background-color: #F4F4F4;
  color: #FFFFFF;
  padding: 0.571em 1em;
  margin: 0;
  text-align: right;
  position: relative;
  border-top: 0 none;
}
body .ui-dialog .ui-dialog-footer button {
  margin: 0 0.5em 0 0;
  width: auto;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content {
  padding: 1em;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span {
  float: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 12px;
  margin: 0;
}
body .ui-dialog.ui-confirm-dialog .ui-dialog-content > span.ui-icon {
  margin-right: 0.35em;
  font-size: 14px;
}
body .ui-lightbox {
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-lightbox .ui-lightbox-caption {
  border: 1px solid #102B4E;
  background-color: #102B4E;
  color: #FFFFFF;
  padding: 0.571em 1em;
  font-weight: 400;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-caption-text {
  color: #FFFFFF;
  margin: 0;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close {
  padding: 0;
  color: #FFFFFF;
  overflow: hidden;
  line-height: 1;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:hover {
  color: #FFFFFF;
}
body .ui-lightbox .ui-lightbox-caption .ui-lightbox-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-lightbox .ui-lightbox-content-wrapper {
  overflow: hidden;
  background-color: #FFFFFF;
  color: #333333;
  border: 0 none;
  padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-size: 3em;
  color: #FFFFFF;
  margin-top: -0.5em;
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-left:hover, body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-nav-right:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-lightbox .ui-lightbox-content-wrapper .ui-lightbox-content.ui-lightbox-loading ~ a {
  display: none;
}
body .ui-overlaypanel {
  background-color: #FFFFFF;
  color: #333333;
  padding: 0;
  border: 1px solid #F4F4F4;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
body .ui-overlaypanel .ui-overlaypanel-content {
  padding: 0.571em 1em;
}
body .ui-overlaypanel .ui-overlaypanel-close {
  background-color: #2096CD;
  color: #FFFFFF;
  width: 1.538em;
  height: 1.538em;
  line-height: 1.538em;
  text-align: center;
  position: absolute;
  top: -0.769em;
  right: -0.769em;
  -moz-transition: color 0.2s;
  -o-transition: color 0.2s;
  -webkit-transition: color 0.2s;
  transition: color 0.2s;
}
body .ui-overlaypanel .ui-overlaypanel-close .ui-overlaypanel-close-icon {
  line-height: inherit;
}
body .ui-overlaypanel .ui-overlaypanel-close:hover {
  background-color: #102B4E;
  color: #FFFFFF;
}
body .ui-overlaypanel .ui-overlaypanel-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-overlaypanel:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: #FFFFFF;
}
body .ui-overlaypanel:before {
  border-color: rgba(244, 244, 244, 0);
  border-bottom-color: #F4F4F4;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:after {
  border-top-color: #FFFFFF;
}
body .ui-overlaypanel.ui-overlaypanel-flipped:before {
  border-top-color: #F4F4F4;
}
body .ui-sidebar {
  background-color: #FFFFFF;
  color: #333333;
  padding: 0.571em 1em;
  border: 1px solid #F4F4F4;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-sidebar .ui-sidebar-close {
  color: #4D4D4D;
}
body .ui-sidebar .ui-sidebar-close:hover {
  color: #333333;
}
body .ui-tooltip .ui-tooltip-text {
  background-color: #2861A4;
  color: #FFFFFF;
  padding: 0.429em;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
body .ui-tooltip.ui-tooltip-right .ui-tooltip-arrow {
  border-right-color: #2861A4;
}
body .ui-tooltip.ui-tooltip-left .ui-tooltip-arrow {
  border-left-color: #2861A4;
}
body .ui-tooltip.ui-tooltip-top .ui-tooltip-arrow {
  border-top-color: #2861A4;
}
body .ui-tooltip.ui-tooltip-bottom .ui-tooltip-arrow {
  border-bottom-color: #2861A4;
}
body .ui-fileupload .ui-fileupload-buttonbar {
  background-color: #102B4E;
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  color: #FFFFFF;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-buttonbar .ui-button {
  margin-right: 8px;
}
body .ui-fileupload .ui-fileupload-content {
  background-color: #FFFFFF;
  padding: 0.571em 1em;
  border: 1px solid #102B4E;
  color: #333333;
}
body .ui-fileupload .ui-progressbar {
  top: 0;
}
body .ui-fileupload-choose:not(.ui-state-disabled):hover {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  background-color: #2861A4;
  color: #FFFFFF;
  border-color: #2861A4;
}
body .ui-fileupload-choose.ui-state-focus {
  outline: 0 none;
  outline-offset: 0px;
  -webkit-box-shadow: 0 0 0 0.1em #addcf2;
  -moz-box-shadow: 0 0 0 0.1em #addcf2;
  box-shadow: 0 0 0 0.1em #addcf2;
}
body .ui-breadcrumb {
  background-color: #FFFFFF;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
}
body .ui-breadcrumb ul li .ui-menuitem-link {
  color: #333333;
  margin: 0;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-breadcrumb ul li .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-breadcrumb ul li.ui-breadcrumb-chevron {
  margin: 0 0.5em 0 0.5em;
  color: #4D4D4D;
}
body .ui-breadcrumb ul li:first-child a {
  color: #4D4D4D;
  margin: 0;
}
body .ui-breadcrumb ul li .ui-menuitem-icon {
  color: #4D4D4D;
}
body .ui-contextmenu {
  padding: 0;
  background-color: #102B4E;
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-contextmenu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-contextmenu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-submenu-list {
  padding: 0;
  background-color: #102B4E;
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-contextmenu .ui-menuitem {
  margin: 0.1em;
}
body .ui-contextmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #2861A4;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-contextmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-contextmenu .ui-menu-separator {
  border: none;
  border-width: 1px 0 0 0;
}
body .ui-megamenu {
  padding: 0;
  background-color: #102B4E;
  border: none;
}
body .ui-megamenu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-megamenu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-megamenu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-megamenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-megamenu .ui-megamenu-submenu-header {
  margin: 0;
  padding: 0.571em 1em;
  color: #FFFFFF;
  background-color: #102B4E;
  font-weight: 400;
  border: 0 none;
}
body .ui-megamenu .ui-megamenu-panel {
  padding: 0;
  background-color: #102B4E;
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-megamenu .ui-menuitem {
  margin: 0.1em;
}
body .ui-megamenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #2861A4;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-megamenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-megamenu.ui-megamenu-vertical .ui-megamenu-root-list > .ui-menuitem > .ui-menuitem-link > .ui-submenu-icon {
  right: 0.429em;
}
body .ui-menu {
  padding: 0;
  background-color: #102B4E;
  border: none;
}
body .ui-menu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-menu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-menu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem {
  margin: 0.1em;
}
body .ui-menu.ui-shadow {
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-menu .ui-submenu-header {
  margin: 0;
  padding: 1em 1.25em;
  color: #FFFFFF;
  background-color: #102B4E;
  font-weight: 400;
  border: 0 none;
}
body .ui-menu .ui-menu-separator {
  border: none;
  border-width: 1px 0 0 0;
}
body .ui-menubar {
  padding: 0;
  background-color: #102B4E;
  border: none;
}
body .ui-menubar .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-menubar .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-menubar .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-menubar .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-menubar .ui-menubar-root-list > .ui-menuitem > .ui-menuitem-link {
  padding: 1em 1.25em;
}
body .ui-menubar .ui-menubar-root-list > .ui-menu-separator {
  border: none;
  border-width: 0 0 0 1px;
}
body .ui-menubar .ui-submenu-list {
  padding: 0;
  background-color: #102B4E;
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-menubar .ui-submenu-list .ui-menuitem {
  margin: 0.1em;
}
body .ui-menubar .ui-submenu-list .ui-menu-separator {
  border: none;
  border-width: 1px 0 0 0;
}
body .ui-menubar .ui-menuitem {
  margin: 0.1em;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #2861A4;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-menubar .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-icon {
  position: static;
}
body .ui-panelmenu .ui-panelmenu-header {
  padding: 0;
}
body .ui-panelmenu .ui-panelmenu-header > a {
  border: 1px solid #102B4E;
  background-color: #102B4E;
  color: #FFFFFF;
  padding: 1em 1.25em;
  font-weight: 400;
  position: static;
  font-size: 12px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header > a:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  outline: 0 none;
  border: 1px solid #6E6E6E;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a {
  border: 1px solid #4D4D4D;
  background-color: #2861A4;
  color: #FFFFFF;
  -moz-border-radius-bottomleft: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
  -moz-border-radius-bottomright: 0;
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover {
  outline: 0 none;
  border: 1px solid #4D4D4D;
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header.ui-state-active > a:hover .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 2px;
}
body .ui-panelmenu .ui-panelmenu-panel:first-child {
  margin-top: 0;
}
body .ui-panelmenu .ui-panelmenu-content {
  padding: 0;
  background-color: #102B4E;
  border: none;
  margin-top: 0;
  position: static;
  border-top: 0 none;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  margin: 0.1em;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-icon, body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-panelmenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-slidemenu {
  padding: 0;
  background-color: #102B4E;
  border: none;
}
body .ui-slidemenu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-slidemenu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-slidemenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-submenu-list {
  padding: 0;
  background-color: #102B4E;
  border: 0 none;
}
body .ui-slidemenu .ui-menuitem {
  margin: 0.1em;
}
body .ui-slidemenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #2861A4;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-slidemenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-slidemenu .ui-slidemenu-backward {
  margin: 0;
  padding: 0.571em 1em;
  color: #FFFFFF;
  background-color: #102B4E;
  font-weight: 400;
  border: 0 none;
}
body .ui-slidemenu .ui-slidemenu-backward:hover {
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-slidemenu .ui-menu-separator {
  border: none;
  border-width: 1px 0 0 0;
}
body .ui-steps {
  position: relative;
}
body .ui-steps .ui-steps-item {
  background-color: transparent;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link {
  display: inline-block;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-steps .ui-steps-item .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  color: #333333;
  background-color: #FFFFFF;
  border: 1px solid #4D4D4D;
  position: relative;
  top: 14px;
  margin-bottom: 12px;
  width: 28px;
  height: 28px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  display: block;
  margin-top: 6px;
  color: #333333;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-number {
  background: #2861A4;
  color: #FFFFFF;
}
body .ui-steps .ui-steps-item.ui-state-highlight .ui-steps-title {
  font-weight: 700;
  color: #333333;
}
body .ui-steps:before {
  content: " ";
  border-top: 1px solid #102B4E;
  width: 100%;
  top: 45%;
  left: 0;
  display: block;
  position: absolute;
}
body .ui-tabmenu {
  border: none;
  margin-top: -15px;
  margin-bottom: -10px;
}
body .ui-tabmenu .ui-tabmenu-nav {
  padding: 0;
  background: transparent;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem {
  position: static;
  border: 1px solid #102B4E;
  background-color: #102B4E;
  margin-right: 2px;
  margin-left: 9px;
  margin-top: 3px;
  margin-bottom: 1px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link {
  color: #FFFFFF;
  padding: 0.571em 1em;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover {
  background-color: #2861A4;
  border: 1px solid #6E6E6E;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem:not(.ui-state-active):not(.ui-state-disabled):hover .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active {
  background-color: #2861A4;
  border: 1px solid #4D4D4D;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tabmenu .ui-tabmenu-nav .ui-tabmenuitem.ui-state-active .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-tieredmenu {
  padding: 0;
  background-color: #102B4E;
  border: none;
}
body .ui-tieredmenu .ui-menuitem-link {
  padding: 1em 1.25em;
  color: #FFFFFF;
  font-weight: normal;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem-link .ui-menuitem-icon {
  color: #FFFFFF;
  margin-right: 0.5em;
}
body .ui-tieredmenu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem-link:hover .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem-link:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: inset 0 0 0 0.2em #addcf2;
  -moz-box-shadow: inset 0 0 0 0.2em #addcf2;
  box-shadow: inset 0 0 0 0.2em #addcf2;
}
body .ui-tieredmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem-link.ui-menuitem-link-active .ui-menuitem-icon {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-submenu-list {
  padding: 0;
  background-color: #102B4E;
  border: 2px solid #F4F4F4;
  -webkit-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
  box-shadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-tieredmenu .ui-menuitem {
  margin: 0.1em;
}
body .ui-tieredmenu .ui-menuitem .ui-menuitem-link .ui-submenu-icon {
  right: 0.429em;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  background-color: #2861A4;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-icon, body .ui-tieredmenu .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-tieredmenu .ui-menu-separator {
  border: none;
  border-width: 1px 0 0 0;
}
body .ui-message {
  padding: 0.429em;
  margin: 0;
}
body .ui-message.ui-message-info {
  background-color: #37A9E8;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-message.ui-message-info .ui-message-icon {
  color: #FFFFFF;
}
body .ui-message.ui-message-success {
  background-color: #008200;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-message.ui-message-success .ui-message-icon {
  color: #FFFFFF;
}
body .ui-message.ui-message-warn {
  background-color: #DA7029;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-message.ui-message-warn .ui-message-icon {
  color: #FFFFFF;
}
body .ui-message.ui-message-error {
  background-color: #D62D0A;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-message.ui-message-error .ui-message-icon {
  color: #FFFFFF;
}
body .ui-message .ui-message-icon {
  font-size: 1.25em;
}
body .ui-message .ui-message-text {
  font-size: 1em;
}
body .ui-messages {
  padding: 1em;
  margin: 1em 0;
}
body .ui-messages.ui-messages-info {
  background-color: #37A9E8;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-messages.ui-messages-info .ui-messages-icon {
  color: #FFFFFF;
}
body .ui-messages.ui-messages-info .ui-messages-close {
  color: #FFFFFF;
}
body .ui-messages.ui-messages-success {
  background-color: #008200;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-messages.ui-messages-success .ui-messages-icon {
  color: #1ea97c;
}
body .ui-messages.ui-messages-success .ui-messages-close {
  color: #FFFFFF;
}
body .ui-messages.ui-messages-warn {
  background-color: #DA7029;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-messages.ui-messages-warn .ui-messages-icon {
  color: #FFFFFF;
}
body .ui-messages.ui-messages-warn .ui-messages-close {
  color: #FFFFFF;
}
body .ui-messages.ui-messages-error {
  background-color: #D62D0A;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-messages.ui-messages-error .ui-messages-icon {
  color: #ff5757;
}
body .ui-messages.ui-messages-error .ui-messages-close {
  color: #FFFFFF;
}
body .ui-messages .ui-messages-close {
  top: 0.25em;
  right: 0.5em;
  font-size: 1.5em;
  overflow: hidden;
  line-height: 1;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: box-shadow 0.2s;
  -o-transition: box-shadow 0.2s;
  -webkit-transition: box-shadow 0.2s;
  transition: box-shadow 0.2s;
}
body .ui-messages .ui-messages-close:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-messages .ui-messages-icon {
  font-size: 2em;
}
body .ui-toast .ui-toast-message {
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  /*margin: $toastMessageMargin;*/
  padding: 0.75rem 0.75rem;
  margin: 0;
  border-radius: 6px;
  font-size: 10px;
  height: 70px;
  width: fit-content;
}
body .ui-toast .ui-toast-message.ui-toast-message-info {
  background-color: #37A9E8;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-toast .ui-toast-message.ui-toast-message-info .ui-toast-close-icon {
  color: #FFFFFF;
}
body .ui-toast .ui-toast-message.ui-toast-message-success {
  /* background-color: $successMessageBgColor;
   border: $successMessageBorder;
   color: $successMessageTextColor;*/
  background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 1px;
  color: #1ea97c;
  font-size: 12px;
}
body .ui-toast .ui-toast-message.ui-toast-message-success .ui-toast-close-icon {
  /*  color: $successMessageIconColor;*/
  color: #1ea97c;
  font-size: 15px;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn {
  background-color: #DA7029;
  border: 0 none;
  color: #FFFFFF;
}
body .ui-toast .ui-toast-message.ui-toast-message-warn .ui-toast-close-icon {
  color: #FFFFFF;
}
body .ui-toast .ui-toast-message.ui-toast-message-error {
  /*  background-color: $errorMessageBgColor;
    border: $errorMessageBorder;
    color: $errorMessageTextColor;*/
  background: #ffe7e6;
  border: solid #ff5757;
  border-width: 1px;
  color: #ff5757;
  font-size: 12px;
}
body .ui-toast .ui-toast-message.ui-toast-message-error .ui-toast-close-icon {
  /*color: $errorMessageTextColor;*/
  color: #ff5757;
  font-size: 15px;
}
body .ui-galleria .ui-galleria-nav-prev,
body .ui-galleria .ui-galleria-nav-next {
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  position: absolute;
  color: #4D4D4D;
}
body .ui-galleria .ui-galleria-nav-prev:hover,
body .ui-galleria .ui-galleria-nav-next:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
body .ui-galleria .ui-galleria-nav-prev {
  font-size: 14px;
}
body .ui-galleria .ui-galleria-nav-next {
  font-size: 14px;
}
body .ui-inplace {
  min-height: 2.357em;
}
body .ui-inplace .ui-inplace-display {
  padding: 0.429em;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -moz-transition: background-color 0.2s;
  -o-transition: background-color 0.2s;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
}
body .ui-inplace .ui-inplace-display:not(.ui-state-disabled):hover {
  background-color: #F4F4F4;
  color: #333333;
}
body .ui-inplace .ui-inplace-display:focus {
  outline: 0 none;
  outline-offset: 0;
  -webkit-box-shadow: 0 0 0 0.2em #addcf2;
  -moz-box-shadow: 0 0 0 0.2em #addcf2;
  box-shadow: 0 0 0 0.2em #addcf2;
}
body .ui-fluid .ui-inplace.ui-inplace-closable .ui-inplace-content > :first-child {
  display: inline-block;
  width: calc(100% - 2.357em);
}
body .ui-progressbar {
  border: 0 none;
  height: 24px;
  background-color: #6E6E6E;
}
body .ui-progressbar .ui-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #2861A4;
}
body .ui-progressbar .ui-progressbar-label {
  margin-top: 0;
  color: #333333;
  line-height: 24px;
}
body .ui-terminal {
  background-color: #FFFFFF;
  color: #333333;
  border: 1px solid #102B4E;
  padding: 0.571em 1em;
}
body .ui-terminal .ui-terminal-input {
  font-size: 12px;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  height: 14px;
}
body .ui-terminal .ui-terminal-command {
  height: 14px;
}

/*
Provide any app specific custom or override button rules you want
above and beyond what Web Enablement provides in this file
 */
/*!
 ******************************** [START] Ford Fonts *********************************
 * This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC,
 * The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute,
 * convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com
 */
@font-face {
  /* Ford Antenna Regular */
  font-family: "FordAntenna";
  src: url("/assets/webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  /* Ford Antenna Bold */
  font-family: "FordAntenna";
  src: url("/assets/webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  /* Ford Antenna Regular Italic */
  font-family: "FordAntenna";
  src: url("/assets/webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  /* Ford Antenna Bold Italic */
  font-family: "FordAntenna";
  src: url("/assets/webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  /* Ford Antenna Medium */
  font-family: "FordAntennaMedium";
  src: url("/assets/webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  /* Ford Antenna Light */
  font-family: "FordAntennaLight";
  src: url("/assets/webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  /* Ford Antenna Light Italic */
  font-family: "FordAntennaLight";
  src: url("/assets/webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face {
  /* Ford Antenna Condensed Regular */
  font-family: "FordAntennaCond";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  /* Ford Antenna Condensed Bold */
  font-family: "FordAntennaCond";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  /* Ford Antenna Condensed Regular Italic */
  font-family: "FordAntennaCond";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  /* Ford Antenna Condensed Bold Italic */
  font-family: "FordAntennaCond";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
@font-face {
  /* Ford Antenna Condensed Medium */
  font-family: "FordAntennaCondMed";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  /* Ford Antenna Condensed Medium Italic*/
  font-family: "FordAntennaCondMed";
  src: url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-5.woff2") format("woff2"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-3.woff") format("woff"), url("/assets/webcore/fonts/Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 500;
}
/********************************* [END] Ford Fonts *********************************/
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

.p-grid {
  margin: 0;
}

body {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 0 auto;
  max-width: 1440px;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
body i.fas,
body i.far,
body i.fal,
body i.fad,
body i.fab {
  margin-right: 0.4em;
}
body dl, body h5 {
  margin: 0;
  padding: 0;
}
body dd {
  margin-left: 0;
  word-wrap: break-word;
  padding: 0;
}
body dt {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  padding: 0.75em 0 0;
}

/* Display
-------------------------------------------------- */
.wc-d-none {
  display: none !important;
}

.wc-d-inline {
  display: inline !important;
}

.wc-d-inline-block {
  display: inline-block !important;
}

.wc-d-block {
  display: block !important;
}

.wc-d-table {
  display: table !important;
}

.wc-d-table-cell {
  display: table-cell !important;
}

.wc-d-table-row {
  display: table-row !important;
}

.wc-d-flex {
  display: flex !important;
}

.wc-d-inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 480px) {
  .wc-d-xs-none {
    display: none !important;
  }

  .wc-d-xs-inline {
    display: inline !important;
  }

  .wc-d-xs-inline-block {
    display: inline-block !important;
  }

  .wc-d-xs-block {
    display: block !important;
  }

  .wc-d-xs-table {
    display: table !important;
  }

  .wc-d-xs-table-cell {
    display: table-cell !important;
  }

  .wc-d-xs-table-row {
    display: table-row !important;
  }

  .wc-d-xs-flex {
    display: flex !important;
  }

  .wc-d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 576px) {
  .wc-d-sm-none {
    display: none !important;
  }

  .wc-d-sm-inline {
    display: inline !important;
  }

  .wc-d-sm-inline-block {
    display: inline-block !important;
  }

  .wc-d-sm-block {
    display: block !important;
  }

  .wc-d-sm-table {
    display: table !important;
  }

  .wc-d-sm-table-cell {
    display: table-cell !important;
  }

  .wc-d-sm-table-row {
    display: table-row !important;
  }

  .wc-d-sm-flex {
    display: flex !important;
  }

  .wc-d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .wc-d-md-none {
    display: none !important;
  }

  .wc-d-md-inline {
    display: inline !important;
  }

  .wc-d-md-inline-block {
    display: inline-block !important;
  }

  .wc-d-md-block {
    display: block !important;
  }

  .wc-d-md-table {
    display: table !important;
  }

  .wc-d-md-table-cell {
    display: table-cell !important;
  }

  .wc-d-md-table-row {
    display: table-row !important;
  }

  .wc-d-md-flex {
    display: flex !important;
  }

  .wc-d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .wc-d-lg-none {
    display: none !important;
  }

  .wc-d-lg-inline {
    display: inline !important;
  }

  .wc-d-lg-inline-block {
    display: inline-block !important;
  }

  .wc-d-lg-block {
    display: block !important;
  }

  .wc-d-lg-table {
    display: table !important;
  }

  .wc-d-lg-table-cell {
    display: table-cell !important;
  }

  .wc-d-lg-table-row {
    display: table-row !important;
  }

  .wc-d-lg-flex {
    display: flex !important;
  }

  .wc-d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .wc-d-xl-none {
    display: none !important;
  }

  .wc-d-xl-inline {
    display: inline !important;
  }

  .wc-d-xl-inline-block {
    display: inline-block !important;
  }

  .wc-d-xl-block {
    display: block !important;
  }

  .wc-d-xl-table {
    display: table !important;
  }

  .wc-d-xl-table-cell {
    display: table-cell !important;
  }

  .wc-d-xl-table-row {
    display: table-row !important;
  }

  .wc-d-xl-flex {
    display: flex !important;
  }

  .wc-d-xl-inline-flex {
    display: inline-flex !important;
  }
}
/* Grid
-------------------------------------------------- */
.wc-col-half,
.wc-col-1-half,
.wc-col-2-half,
.wc-col-3-half,
.wc-col-4-half,
.wc-col-5-half,
.wc-col-6-half,
.wc-col-7-half,
.wc-col-8-half,
.wc-col-9-half,
.wc-col-10-half,
.wc-col-11-half {
  padding: 0.5em;
}

.wc-col-half {
  width: 4.1667% !important;
}

.wc-col-1-half {
  width: 12.5% !important;
}

.wc-col-2-half {
  width: 20.8333% !important;
}

.wc-col-3-half {
  width: 29.1667% !important;
}

.wc-col-4-half {
  width: 37.5% !important;
}

.wc-col-5-half {
  width: 45.8333% !important;
}

.wc-col-6-half {
  width: 54.1667% !important;
}

.wc-col-7-half {
  width: 62.5% !important;
}

.wc-col-8-half {
  width: 70.8333% !important;
}

.wc-col-9-half {
  width: 79.1667% !important;
}

.wc-col-10-half {
  width: 87.5% !important;
}

.wc-col-11-half {
  width: 95.8333% !important;
}

@media screen and (min-width: 480px) {
  .wc-xs-half {
    width: 4.1667% !important;
  }

  .wc-xs-1-half {
    width: 12.5% !important;
  }

  .wc-xs-2-half {
    width: 20.8333% !important;
  }

  .wc-xs-3-half {
    width: 29.1667% !important;
  }

  .wc-xs-4-half {
    width: 37.5% !important;
  }

  .wc-xs-5-half {
    width: 45.8333% !important;
  }

  .wc-xs-6-half {
    width: 54.1667% !important;
  }

  .wc-xs-7-half {
    width: 62.5% !important;
  }

  .wc-xs-8-half {
    width: 70.8333% !important;
  }

  .wc-xs-9-half {
    width: 79.1667% !important;
  }

  .wc-xs-10-half {
    width: 87.5% !important;
  }

  .wc-xs-11-half {
    width: 95.8333% !important;
  }
}
@media screen and (min-width: 576px) {
  .wc-sm-half {
    width: 4.1667% !important;
  }

  .wc-sm-1-half {
    width: 12.5% !important;
  }

  .wc-sm-2-half {
    width: 20.8333% !important;
  }

  .wc-sm-3-half {
    width: 29.1667% !important;
  }

  .wc-sm-4-half {
    width: 37.5% !important;
  }

  .wc-sm-5-half {
    width: 45.8333% !important;
  }

  .wc-sm-6-half {
    width: 54.1667% !important;
  }

  .wc-sm-7-half {
    width: 62.5% !important;
  }

  .wc-sm-8-half {
    width: 70.8333% !important;
  }

  .wc-sm-9-half {
    width: 79.1667% !important;
  }

  .wc-sm-10-half {
    width: 87.5% !important;
  }

  .wc-sm-11-half {
    width: 95.8333% !important;
  }
}
@media screen and (min-width: 768px) {
  .wc-md-half {
    width: 4.1667% !important;
  }

  .wc-md-1-half {
    width: 12.5% !important;
  }

  .wc-md-2-half {
    width: 20.8333% !important;
  }

  .wc-md-3-half {
    width: 29.1667% !important;
  }

  .wc-md-4-half {
    width: 37.5% !important;
  }

  .wc-md-5-half {
    width: 45.8333% !important;
  }

  .wc-md-6-half {
    width: 54.1667% !important;
  }

  .wc-md-7-half {
    width: 62.5% !important;
  }

  .wc-md-8-half {
    width: 70.8333% !important;
  }

  .wc-md-9-half {
    width: 79.1667% !important;
  }

  .wc-md-10-half {
    width: 87.5% !important;
  }

  .wc-md-11-half {
    width: 95.8333% !important;
  }
}
@media screen and (min-width: 992px) {
  .wc-lg-half {
    width: 4.1667% !important;
  }

  .wc-lg-1-half {
    width: 12.5% !important;
  }

  .wc-lg-2-half {
    width: 20.8333% !important;
  }

  .wc-lg-3-half {
    width: 29.1667% !important;
  }

  .wc-lg-4-half {
    width: 37.5% !important;
  }

  .wc-lg-5-half {
    width: 45.8333% !important;
  }

  .wc-lg-6-half {
    width: 54.1667% !important;
  }

  .wc-lg-7-half {
    width: 62.5% !important;
  }

  .wc-lg-8-half {
    width: 70.8333% !important;
  }

  .wc-lg-9-half {
    width: 79.1667% !important;
  }

  .wc-lg-10-half {
    width: 87.5% !important;
  }

  .wc-lg-11-half {
    width: 95.8333% !important;
  }
}
@media screen and (min-width: 1200px) {
  .wc-xl-half {
    width: 4.1667% !important;
  }

  .wc-xl-1-half {
    width: 12.5% !important;
  }

  .wc-xl-2-half {
    width: 20.8333% !important;
  }

  .wc-xl-3-half {
    width: 29.1667% !important;
  }

  .wc-xl-4-half {
    width: 37.5% !important;
  }

  .wc-xl-5-half {
    width: 45.8333% !important;
  }

  .wc-xl-6-half {
    width: 54.1667% !important;
  }

  .wc-xl-7-half {
    width: 62.5% !important;
  }

  .wc-xl-8-half {
    width: 70.8333% !important;
  }

  .wc-xl-9-half {
    width: 79.1667% !important;
  }

  .wc-xl-10-half {
    width: 87.5% !important;
  }

  .wc-xl-11-half {
    width: 95.8333% !important;
  }
}
/* Text Alignment */
/* Responsive
-------------------------------------------------- */
.wc-text-left {
  text-align: left !important;
}

.wc-text-right {
  text-align: right !important;
}

.wc-text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .wc-text-xs-left {
    text-align: left !important;
  }

  .wc-text-xs-right {
    text-align: right !important;
  }

  .wc-text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .wc-text-sm-left {
    text-align: left !important;
  }

  .wc-text-sm-right {
    text-align: right !important;
  }

  .wc-text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .wc-text-md-left {
    text-align: left !important;
  }

  .wc-text-md-right {
    text-align: right !important;
  }

  .wc-text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .wc-text-lg-left {
    text-align: left !important;
  }

  .wc-text-lg-right {
    text-align: right !important;
  }

  .wc-text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .wc-text-xl-left {
    text-align: left !important;
  }

  .wc-text-xl-right {
    text-align: right !important;
  }

  .wc-text-xl-center {
    text-align: center !important;
  }
}
body a {
  text-decoration: underline;
}

body .ui-autocomplete {
  width: 100% !important;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token {
  width: 100%;
  border: none;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-input-token input {
  width: 100%;
  border: none;
}
body .ui-autocomplete.ui-autocomplete-multiple .ui-autocomplete-multiple-container .ui-autocomplete-token {
  margin: 0.125em;
}
body .ui-calendar.ui-calendar-w-btn .ui-datepicker-trigger.ui-button {
  vertical-align: top;
}
body .ui-dropdown {
  min-width: 5em;
  width: auto !important;
  border: 1px solid #6E6E6E;
}
body .ui-dropdown .ui-dropdown-label {
  color: #333333;
}
body .ui-dropdown .ui-dropdown-trigger .ui-dropdown-trigger-icon {
  color: #333333;
}
body .ui-dropdown:hover .ui-dropdown-label {
  color: #333333;
}
body .ui-dropdown:hover .ui-dropdown-trigger .ui-dropdown-trigger-icon {
  transform: scale(1.1);
  transition: transform 0.1s ease-in-out;
}
body .ui-rating a .ui-rating-icon {
  margin-right: 0.25em;
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-selectbutton .ui-button.ui-state-active {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-selectbutton .ui-button.ui-state-active:not(.ui-state-disabled):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-selectbutton .ui-button.ui-state-active.ui-state-focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-togglebutton.ui-state-active {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-togglebutton.ui-state-active:hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-togglebutton.ui-state-active.ui-state-focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-button {
  box-shadow: inset 0 1em 1em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.2);
  margin: 0.125em;
}
body .ui-button.ui-calendar-button, body .ui-button.ui-autocomplete-dropdown, body .ui-button.ui-spinner-button {
  margin: 0;
}
body .ui-button:enabled:hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-button:enabled:focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
  background-color: #2861A4;
}
body .ui-button:enabled:active {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-button.ui-state-default.ui-button-secondary:enabled:focus, body .ui-buttonset.ui-button-secondary > .ui-button.ui-state-default:enabled:focus {
  background-color: #e6e6e6;
}
body .ui-button.ui-state-default.ui-button-info:enabled:focus, body .ui-buttonset.ui-button-info > .ui-button.ui-state-default:enabled:focus {
  background-color: #2861A4;
}
body .ui-button.ui-state-default.ui-button-success:enabled:focus, body .ui-buttonset.ui-button-success > .ui-button.ui-state-default:enabled:focus {
  background-color: #008200;
}
body .ui-button.ui-state-default.ui-button-warning:enabled:focus, body .ui-buttonset.ui-button-warning > .ui-button.ui-state-default:enabled:focus {
  background-color: #DA7029;
}
body .ui-button.ui-state-default.ui-button-danger:enabled:focus, body .ui-buttonset.ui-button-danger > .ui-button.ui-state-default:enabled:focus {
  background-color: #D62D0A;
}
body .ui-dataview .ui-dataview-header {
  background-color: inherit;
  border: none;
}
body .ui-dataview .ui-dataview-content {
  border: none;
  padding: 0;
}
body .ui-paginator {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
body .ui-paginator .ui-paginator-first,
body .ui-paginator .ui-paginator-prev,
body .ui-paginator .ui-paginator-next,
body .ui-paginator .ui-paginator-last {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-paginator .ui-paginator-first:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-prev:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-next:not(.ui-state-disabled):not(.ui-state-active):hover,
body .ui-paginator .ui-paginator-last:not(.ui-state-disabled):not(.ui-state-active):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-paginator .ui-dropdown {
  border: 1px solid #6E6E6E;
}
body .ui-paginator .ui-dropdown:hover .ui-dropdown-trigger, body .ui-paginator .ui-dropdown:hover .ui-dropdown-label {
  color: #333333;
}
body .ui-paginator .ui-paginator-pages {
  height: 2.286em;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  box-shadow: inset 0 1em 1em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body .ui-paginator .ui-paginator-pages .ui-paginator-page:not(.ui-state-active):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: none;
}
body .ui-table .ui-table-caption .ui-button,
body .ui-table .ui-table-summary .ui-button {
  font-weight: 400;
}
body .ui-panel {
  padding-bottom: 1em;
}
body .ui-panel .ui-panel-titlebar {
  align-items: center;
}
body .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  color: #2861A4;
}
body .ui-panel .ui-panel-content {
  padding: 0.5em 0.75em;
}
body .ui-dialog .ui-dialog-titlebar {
  border: none;
}
body .ui-dialog .ui-dialog-footer {
  border: none;
}
body .ui-dialog.ui-confirmdialog {
  width: 35em;
}
body .ui-dialog.ui-confirmdialog .ui-dialog-content {
  border: none;
  display: flex;
}
body .ui-sidebar {
  padding: 0;
}
body .ui-sidebar .ui-sidebar-close {
  color: #102B4E;
  font-size: 1.7em;
  padding: 0.125em 0.25em;
}
body .ui-fileupload .ui-fileupload-buttonbar {
  background-color: #F2F2F2;
  border: 1px solid #8C8C8C;
  border-bottom: 0 none;
}
body .ui-fileupload .ui-fileupload-content {
  background-color: #FFFFFF;
  border: 1px solid #8C8C8C;
}
body .ui-fileupload .ui-progressbar {
  top: -2px;
}
body .ui-fileupload-choose:not(.ui-state-disabled):hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-fileupload-choose:not(.ui-state-disabled):active {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-fileupload-choose.ui-state-focus {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
}
body .ui-menu {
  background-color: #FFFFFF;
}
body .ui-menubar .ui-submenu-list {
  background-color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header > a .ui-panelmenu-icon {
  float: right;
}
body .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active) > a:hover {
  background-color: #102B4E;
}
body .ui-panelmenu .ui-panelmenu-panel {
  margin-top: 0;
}
body .ui-panelmenu .ui-panelmenu-panel:first-child {
  margin-top: 40px;
}
body .ui-panelmenu .ui-panelmenu-icon {
  float: right;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-number {
  top: 6px;
}
body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
  white-space: unset;
  margin-top: 0;
  display: none;
}
body .ui-steps:before {
  position: relative;
  top: 29px;
}
body .ui-message .ui-message-text {
  padding-left: 0.5em;
}
body .ui-toast .ui-toast-close-icon {
  text-decoration: none;
}
@media screen and (min-width: 480px) {
  body .ui-toast {
    width: 25em;
  }
  body .ui-toast .ui-toast-message {
    font-size: 20px;
  }
}
body .ui-progressbar .ui-progressbar-label {
  color: #FFFFFF;
}
body .ui-terminal {
  font-family: Monospace;
}

body {
  /* horizontal form styling datepicker */
  /* horizontal form styling */
  /*.required label:before,label.required:before,legend.required:before,*/
  /* end of horizontal form styling */
}
body p-autocomplete.ng-invalid.ng-touched > .ui-autocomplete > .ui-inputtext,
body input.ng-dirty.ng-invalid.ng-touched,
body input.ng-invalid.ng-touched,
body input.ng-invalid.ng-touched.ui-inputtext,
body input.ng-invalid.ng-touched.ui-inputtext:hover,
body p-dropdown.ng-invalid.ng-touched .ui-dropdown,
body p-dropdown.ng-invalid.ng-dirty .ui-dropdown,
body p-calendar.ng-invalid.ng-touched .ui-calendar input,
body p-calendar.ng-invalid.ng-touched .ui-calendar .ui-inputtext {
  border: 1px solid #D62D0A;
}
body form {
  border-radius: 5px;
  padding: 0.5em;
  box-shadow: 0 1px 10px #ccc;
}
body form p-calendar .ui-calendar {
  width: 100%;
  white-space: nowrap;
}
body form p-calendar .ui-calendar input {
  width: 80%;
}
body form p-calendar .ui-calendar .ui-calendar-button {
  margin: 0 0.125px 0 0;
}
body form p-dropdown {
  padding: 0;
}
body .ui-datepicker table td > a:hover, body .ui-datepicker table td > span:hover {
  color: #FFFFFF;
}
body .ui-datepicker table td > a.ui-state-disabled:hover, body .ui-datepicker table td > span.ui-state-disabled:hover {
  color: #333333;
}
body .ui-datepicker table td.ui-datepicker-today > a.ui-state-disabled:hover, body .ui-datepicker table td.ui-datepicker-today > span.ui-state-disabled:hover {
  color: #FFFFFF;
}
body .select-all-none {
  padding: 0.5em;
}
body p-dropdown.inline-dropdown-pad {
  padding-left: 0.5em;
}
body .ui-dropdown.form-dropdown {
  width: 100% !important;
}
body input[type=text],
body input[type=number],
body textarea,
body select {
  width: 100%;
}
body input[type=text],
body input[type=number] {
  border: 1px solid #6E6E6E;
}
body a.ui-button {
  box-shadow: inset 0 1em 1em rgba(0, 0, 0, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.2);
}
body a.ui-button:hover {
  box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
  background-color: #2861A4;
  color: #FFFFFF;
}
body .ui-button-icon-only .ui-button-icon-left {
  font-size: 1.25em;
}
body .ui-button-link {
  margin: 0.0625em;
  text-decoration: underline;
  background: none !important;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0276B3;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
}
body i .button-icon {
  margin-left: -0.9em;
}
body .ui-paginator.ui-paginator-top {
  margin: 0.5em 0;
  align-items: center;
}
body .ui-paginator.ui-paginator-top .ui-paginator-right-content {
  display: flex;
  color: #333333;
}
body .ui-paginator.ui-paginator-top .ui-paginator-left-content {
  display: flex;
  color: #333333;
}
body .ui-paginator.ui-paginator-bottom {
  margin: 0.5em 0;
  align-items: center;
}
body .ui-paginator.ui-paginator-bottom .ui-paginator-right-content {
  display: flex;
  color: #333333;
}
body .ui-paginator.ui-paginator-bottom .ui-paginator-left-content {
  display: flex;
  color: #333333;
}
body .ui-paginator .ui-paginator-right-content {
  margin-left: auto;
}
body .ui-paginator .ui-paginator-left-content {
  margin-right: auto;
}
body .ui-paginator .ui-paginator-left-content p-dropdown {
  margin: 0;
}
body .ui-paginator .ui-paginator-left-content .resultsPerPageSpan {
  align-self: center;
  margin-right: 0.25em;
  font-weight: bold;
}
@media screen and (max-width: 579px) {
  body .ui-paginator .ui-paginator-left-content {
    flex-basis: 100%;
  }
  body .ui-paginator a.ui-paginator-last.ui-paginator-element {
    margin-right: auto;
  }
  body .ui-paginator .ui-paginator-left-content .resultsPerPageDropdown {
    margin-right: auto;
  }
  body .ui-paginator .resultsPerPageSpan {
    margin-left: 0.7em;
  }
}
@media screen and (max-width: 398px) {
  body .ui-paginator a.ui-paginator-first.ui-paginator-element {
    margin-left: auto;
  }
  body .ui-paginator .ui-paginator-right-content {
    margin-right: auto;
  }
  body .ui-paginator .resultsPerPageSpan {
    margin-left: auto;
  }
}
body .ui-table .ui-table-loading {
  opacity: 1;
}
@media (min-width: 768px) {
  body .ui-table .ui-table-caption,
body .ui-table .ui-table-summary {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}
body .ui-table.ui-table-responsive .ui-table-thead > tr > th.table-checkbox,
body .ui-table.ui-table-responsive .ui-table-tbody > tr > td.table-checkbox {
  padding: 0.25em;
  width: 2.5em;
  text-align: left;
}
@media screen and (min-width: 576px) {
  body .ui-table.ui-table-responsive .ui-table-thead > tr > th.table-checkbox,
body .ui-table.ui-table-responsive .ui-table-tbody > tr > td.table-checkbox {
    padding: 0.25em;
    width: 2.5em;
    text-align: center;
  }
}
@media screen and (min-width: 576px) and (max-width: 40em) {
  body .ui-table.ui-table-responsive .ui-table-thead > tr > th.table-checkbox,
body .ui-table.ui-table-responsive .ui-table-tbody > tr > td.table-checkbox {
    padding: 0.25em;
    width: 2.5em;
    text-align: left;
  }
}
body .ui-table.ui-table-responsive table .ui-table-thead > tr {
  border-right: 1px solid #6E6E6E;
  border-bottom: 0 none;
}
body .ui-table.ui-table-responsive table .ui-table-thead > tr > th,
body .ui-table.ui-table-responsive table .ui-table-thead > tr > td {
  padding: 0.571em 0.35em;
}
body .ui-table.ui-table-responsive table .ui-table-thead > tr > th[class*=p-col],
body .ui-table.ui-table-responsive table .ui-table-thead > tr > td[class*=p-col] {
  border: 0 none;
}
@media screen and (min-width: 768px) {
  body .ui-table.ui-table-responsive table .ui-table-thead > tr {
    border-top: 1px solid #6E6E6E;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: 1px solid #6E6E6E;
  }
  body .ui-table.ui-table-responsive table .ui-table-thead > tr.p-grid > th[class*=p-col],
body .ui-table.ui-table-responsive table .ui-table-thead > tr.p-grid > td[class*=p-col] {
    border-top: 0 none;
    border-right: 1px solid #6E6E6E;
    border-bottom: 0 none;
    border-left: 0 none;
    display: block;
  }
}
body .ui-table.ui-table-responsive table .ui-table-tbody {
  border-bottom: 1px solid #6E6E6E;
}
body .ui-table.ui-table-responsive table .ui-table-tbody > tr {
  border-bottom: 0 none;
}
@media screen and (min-width: 768px) {
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr.p-grid {
    border-top: 1px solid #6E6E6E;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: 1px solid #6E6E6E;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr.p-grid > th[class*=p-col],
body .ui-table.ui-table-responsive table .ui-table-tbody > tr.p-grid > td[class*=p-col] {
    border-top: 0 none;
    border-right: 1px solid #6E6E6E;
    border-bottom: 0 none;
    border-left: 0 none;
    display: block;
  }
}
body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td {
  /**
  * For some reason in PrimeNG, the td widths on the responsive table are marked !important so they need to
  * be overridden when combining with the flexgrid at the max-width 40em breakpoint.
  */
}
@media screen and (max-width: 40em) {
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-1 {
    width: 8.3333% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-2 {
    width: 16.6667% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-3 {
    width: 25% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-4 {
    width: 33.3333% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-5 {
    width: 41.6667% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-6 {
    width: 50% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-7 {
    width: 58.3333% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-8 {
    width: 66.6667% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-9 {
    width: 75% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-10 {
    width: 83.3333% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-11 {
    width: 91.6667% !important;
  }
  body .ui-table.ui-table-responsive table .ui-table-tbody > tr > td.p-col-12 {
    width: 100% !important;
  }
}
body .ui-table.ui-table-responsive table .ui-table-tfoot {
  border-bottom: 1px solid #6E6E6E;
}
body .ui-table.ui-table-responsive table .ui-table-tfoot a {
  color: #FFFFFF;
  text-decoration: underline;
}
body .ui-table.ui-table-responsive table .ui-table-tfoot a:active, body .ui-table.ui-table-responsive table .ui-table-tfoot a:hover {
  color: #FFFFFF;
  text-decoration: underline;
}
body .ui-table.ui-table-responsive table .ui-table-tfoot > tr {
  border: 1px solid #6E6E6E;
  border-bottom: 0 none;
}
@media screen and (min-width: 768px) {
  body .ui-table.ui-table-responsive table .ui-table-tfoot > tr.p-grid {
    border-top: 1px solid #6E6E6E;
    border-right: 0 none;
    border-bottom: 0 none;
    border-left: 1px solid #6E6E6E;
  }
  body .ui-table.ui-table-responsive table .ui-table-tfoot > tr.p-grid > th[class*=p-col],
body .ui-table.ui-table-responsive table .ui-table-tfoot > tr.p-grid > td[class*=p-col] {
    border-top: 0 none;
    border-right: 1px solid #6E6E6E;
    border-bottom: 0 none;
    border-left: 0 none;
    display: block;
  }
}
body .ui-table.ui-table-responsive table .ui-column-title {
  padding: 0.4em;
  min-width: 30%;
  margin: -0.4em 1em -0.4em -0.4em;
  font-weight: bold;
}
body .ui-table.ui-table-responsive table .ui-column-title[class*=p-col] {
  padding: 0.5em;
  min-width: 0;
  margin: 0;
}
body table .ui-widget-content td:first-child {
  overflow: visible;
}
body .ui-panel .ui-panel-titlebar a {
  color: #FFFFFF;
  text-decoration: underline;
}
body .ui-panel p-header {
  display: flex;
}
body .ui-panel .ui-panel-content h5 {
  margin: 0 !important;
}
body .ui-panel .ui-panel-content .p-grid {
  margin-bottom: 0.5em !important;
}
body .ui-panel-view {
  text-align: right;
  width: 100%;
  margin-top: -38px;
  margin-left: 13px;
}
body .ui-dataview .ui-dataview-content .ui-panel-footer {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 2.75em;
  justify-content: space-between;
}
body .ui-dataview .ui-dataview-content .ui-panel-footer a {
  color: #333333;
  text-decoration: underline;
}
body .ui-dataview .ui-dataview-content .ui-panel-footer a:hover {
  color: #2096CD;
}
body .ui-sidebar {
  overflow-y: auto;
  border: none;
}
body .ui-sidebar .user-info {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
}
body .ui-sidebar .ui-sidebar-close {
  margin-top: 0.2em;
}
body .btn-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .btn-sidebar button {
  background: none;
  color: #102B4E;
}
body .btn-sidebar button:enabled:focus {
  box-shadow: none;
}
body .btn-sidebar .ui-button {
  border: none;
  box-shadow: none;
}
body .btn-sidebar .ui-button:hover {
  border: none !important;
  box-shadow: none !important;
  background: #FFFFFF !important;
  color: #2861A4 !important;
}
body .btn-sidebar .ui-button:hover .ui-button-icon-left {
  transform: scale(1.125);
  transition: transform 0.1s ease-in-out;
}
@media screen and (min-width: 768px) {
  body .btn-sidebar {
    display: none;
  }
}
body .ui-menu .ui-menuitem-link .ui-menuitem-text,
body .ui-menu .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-menu .ui-menuitem-link.ui-state-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menu .ui-menuitem-link:hover {
  background-color: #2861A4;
}
body .ui-menu .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
}
body .ui-menubar a.user-info {
  color: #FFFFFF;
}
body .ui-menubar .ui-menubar-custom .user-info {
  float: right;
  margin-right: 1em;
  margin-top: 0.8em;
  color: #FFFFFF;
}
body .ui-menubar .ui-submenu-list .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-submenu-list .ui-menuitem-link .ui-menuitem-icon {
  color: #333333;
}
body .ui-menubar .ui-submenu-list .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text,
body .ui-menubar .ui-submenu-list .ui-menuitem-link:hover .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem > .ui-menuitem-link-active {
  background-color: #2861A4;
}
body .ui-menubar .ui-menuitem > .ui-menuitem-link-active .ui-menuitem-text {
  color: #FFFFFF;
}
body .ui-menubar .ui-menuitem > .ui-menuitem-link-active .ui-menuitem-icon, body .ui-menubar .ui-menuitem > .ui-menuitem-link-active .ui-submenu-icon {
  color: #FFFFFF;
}
body .ui-panelmenu .ui-panelmenu-header {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
}
body .ui-panelmenu .ui-panelmenu-header > a,
body .ui-panelmenu .ui-panelmenu-header .ui-state-active > a,
body .ui-panelmenu .ui-panelmenu-header :not(.ui-state-active) > a,
body .ui-panelmenu .ui-panelmenu-header :not(.ui-state-active) > a:hover {
  border-top: 1px solid #6E6E6E;
  border-bottom: 1px solid #6E6E6E;
  border-left: none !important;
  border-right: none !important;
}
body .ui-panelmenu .ui-panelmenu-content {
  background-color: #FFFFFF;
  border: none;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem {
  border-radius: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link {
  color: #333333;
  border-radius: 0;
}
body .ui-panelmenu .ui-panelmenu-content .ui-menuitem .ui-menuitem-link.ui-menuitem-link-active {
  background-color: #2861A4;
  color: #FFFFFF;
}
@media screen and (min-width: 576px) {
  body .ui-steps:before {
    top: 34px;
  }
}
@media screen and (min-width: 768px) {
  body .ui-steps .ui-steps-item .ui-menuitem-link .ui-steps-title {
    display: block;
  }
}
body .ui-steps .ui-steps-item.ui-state-disabled {
  opacity: 1;
}
body .ui-steps .ui-steps-item.ui-state-disabled .ui-steps-number {
  color: #333333;
}
body fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}
body fieldset legend {
  font-weight: 700;
  font-size: 1rem;
}
body label {
  font-weight: 700;
  font-size: 1rem;
}
body .form-actions {
  border-top: 1px solid #e5e5e5;
  padding: 1em;
  margin: 0 -0.3125em 0.3125em -0.3125em;
}
body .dl-horizontal dt {
  text-align: right;
  padding-right: 10px !important;
}
body label.required-star:before,
body legend.required-star:before {
  color: #D62D0A;
  content: " * ";
  display: inline;
}

header,
#app-header {
  padding: 0.5em 0 1em;
  width: 100%;
  flex: 0 0 auto;
}
header .skip-link,
#app-header .skip-link {
  background: #102B4E;
  color: #FFFFFF;
  left: calc(50% - 3.75rem);
  padding: 0.25rem;
  position: absolute;
  text-align: center;
  transform: translateY(-130%);
  transition: transform 0.3s;
  width: 7.5rem;
}
header .skip-link:focus,
#app-header .skip-link:focus {
  transform: translateY(0%);
}

#app-branding h1 {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
}

#app-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#app-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #102B4E;
}
#app-icon > img {
  transition: max-width 0.25s ease-in;
  max-width: 55px;
}
@media screen and (min-width: 480px) {
  #app-icon > img {
    max-width: 100px;
  }
}
@media screen and (min-width: 576px) {
  #app-icon > img {
    max-width: 105px;
  }
}
@media screen and (min-width: 768px) {
  #app-icon > img {
    max-width: 110px;
  }
}
@media screen and (min-width: 992px) {
  #app-icon > img {
    max-width: 115px;
  }
}
@media screen and (min-width: 1200px) {
  #app-icon > img {
    max-width: 120px;
  }
}

#app-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.25em 0 0 0.5em;
}
#app-name h1 {
  margin: 0;
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.125rem;
  padding-top: 10px;
}
@media screen and (min-width: 480px) {
  #app-name h1 {
    font-size: 1.25rem;
    /*20px; this is what it used to be before converting to rems*/
  }
}
@media screen and (min-width: 576px) {
  #app-name h1 {
    font-size: 1.375rem;
  }
}
@media screen and (min-width: 768px) {
  #app-name h1 {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 992px) {
  #app-name h1 {
    font-size: 1.625rem;
  }
}
@media screen and (min-width: 1200px) {
  #app-name h1 {
    font-size: 1.75rem;
  }
}

app-nav {
  display: flex;
  align-items: center;
}
app-nav p-menubar {
  width: 100%;
}

@media screen and (min-width: 768px) {
  app-nav {
    display: unset;
  }
}
@media screen and (min-width: 768px) {
  #app-sidebar {
    display: none;
  }
}
main,
#app-content {
  width: 100%;
  padding: 0 0.25em;
  flex: 1 0 auto;
}
@media screen and (min-width: 576px) {
  main,
#app-content {
    padding: 0 1em;
  }
}

footer,
#app-footer {
  padding: 0.25em 1em;
  width: 100%;
  flex: 0 0 auto;
  background-image: linear-gradient(to bottom, #F4F4F4 0, #FFFFFF 100%);
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 0 0.5em;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
  font-family: FordAntennaCond, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
}

h5, .h5, h6, .h6 {
  font-family: FordAntenna, Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
}

h1, h2 {
  margin-bottom: 0.41666em;
}

h3, .h3 {
  font-size: 1.18rem;
}

h4, .h4 {
  font-size: 1rem;
}

p {
  margin: 0.5em;
}

hr {
  margin-bottom: 1em;
}

a:hover {
  color: #2861A4;
}

hr {
  border: 0;
  border-top: 1px solid #aeaeae;
}