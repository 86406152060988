/* override PrimeNG theme font family */
/*Font paths with obfuscated names from WebType*/
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*ui component Colors coming from the WCAG 2.0 AA Accessibility Compliance guide from GTB*/
/* Backgrounds */
/* Blue */
/* Gray */
/* Text and Icons */
/* CTA Colors */
/* Text Dark */
/*$black:    #000 !default;*/
/* Alerts */
/*Responsive*/
body {
  font-family: FordAntennaLight, Arial, Helvetica, sans-serif;
  color: #333333;
  font-size: 13px;
  background-color: #f3f3f3;
  padding: 0;
  margin: 0;
}
body.layout-scroll-block {
  overflow: hidden;
}

/*.layout-wrapper {
    .layout-topbar {
        background: #252d32;
        height: 70px; //60
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9999;
        @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

        .layout-topbar-content {
            padding: 10px 20px;
            position: relative;

            .topbar-logo {
                height: 40px;
                vertical-align: middle;
            }

            .menu-btn {
                display: none;
                color: #d8d8d8;
                font-size: 24px;
                vertical-align: middle;
                margin-right: 20px;
                margin-left: 20px;

                i {
                    vertical-align: middle;
                }
            }

            .topbar-link {
                color: #d8d8d8;
                position: absolute;
                right: 32px;
                top: 0;
                display: block;
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                padding: 0 16px;
                text-decoration: none;

                &:hover {
                    background-color: #3a4246;
                }
            }
        }
    }

    .layout-sidebar {
        position: fixed;
        left: 0;
        top: 70px; //60
        width: 200px;
        background-color: #2a3238;
        height: 100%;
        z-index: 9999;
        @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

        .layout-menu {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    text-decoration: none;
                    display: block;
                    padding: 12px 24px;
                    color: #d8d8d8;
                    background-color: #2a3238;
                    font-size: 16px;
                    border-left: 4px solid transparent;
                    border-bottom: 1px solid #23292e;

                    i {
                        margin-right: 12px;
                        vertical-align: middle;
                    }

                    span {
                        vertical-align: middle;
                    }

                    &:hover {
                        background-color: #353c43;
                    }

                    &.active{
                       // background-color: #1d2327;
                       background-color: #72add4;
                        border-left-color: #007ad9;

                        i {
                            color: #007ad9;
                        }
                    }
                }
            }
        }
    }

    .layout-editor {
        .layout-editor-button {
            display: block;
            position: fixed;
            width: 52px;
            height: 52px;
            line-height: 52px;
            background-color: #037ad9;
            text-align: center;
            top: 120px;
            right: 0;
            z-index: 1000000;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            transition: background-color .2s;
            box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
            cursor: pointer;
            transition: background-color .3s;
            z-index: 9999;

            &:hover {
                background-color: #0261ad;
            }

            i {
                line-height: inherit;
                font-size: 32px;
                color: #ffffff;
            }
        }

        .layout-editor-panel {
            position: fixed;
            width: 600px;
            height: 100%;
            right: -600px;
            top: 0;
            z-index: 10000;
            transition: right .3s;
            background-color: #2a3238;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
            padding: 1em 0 2em 0;

            &.layout-editor-panel-active {
                right: 0;
            }

            .layout-editor-close-button {
                display: inline-block;
                width: 52px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 1em;
                margin-right: 1em;
                transition: background-color .3s;
                cursor: pointer;

                &:hover {
                    background-color: #4b4b4b;
                }

                i {
                    color: #037ad9;
                    line-height: inherit;
                    font-size: 32px;
                }
            }

            h1 {
                display: inline-block;
                color: #ffffff;
                margin: .5em 1em;
                font-size: 24px;
            }

            button {
                display: block;
                width: 100%;
                border-radius: 0;

                .ui-button-text {
                    padding: 1em;
                }

                .ui-button-icon-left {
                    font-size: 24px;
                }
            }

            .close-button-container {
                text-align: right;
            }

			.highlights-container {
				height: calc(100% - 87px);
				overflow: auto;
				background-color: #2a3238;
				color: #ffffff;
				padding: 1em;

				pre {
					font-size: 16px;
					font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
					white-space: pre-wrap;
                    overflow-wrap: break-word;
                    color: #ffa94d;

					&:focus {
						outline: 0 none;
					}

					.scss-variable {
						color: #4dabf7;
					}

					.scss-comment {
						color: #38d9a9;
					}
				}
			}
        }
    }

    .layout-content {
        margin-left: 200px;
        padding: 84px 24px 24px 24px;
    }

    .layout-footer {
        width: 100%;
        background-color: #343c41;
        padding: 16px;
        padding-left: 266px;
        color: #d8d8d8;
    }
}

.card {
    border-radius: 3px;
    background: #ffffff;
    padding: 16px;
    margin-bottom: 16px;

    &.card-w-title {
        padding-bottom: 16px;
    }

    &.no-gutter {
        margin-bottom: 0;
    }

    h1 {
        font-size: 20px;
        font-weight: 400;
        margin: 24px 0 12px 0;

        &:first-child {
            margin-top: 0px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
    }

    h4 {
        font-size: 14px;
        font-weight: 700;
    }
}


@media (max-width: 1024px) {
    .layout-wrapper {
        .layout-topbar {
            height: 100px;//
            .layout-topbar-content {
                .topbar-logo {
                    height: 28px;
                    margin-top: 6px;
                    margin-left: 100px;//
                }

                .menu-btn {
                    display: inline-block;
                }
            }
        }

        .layout-sidebar {
            left: -250px;

            &.layout-sidebar-active {
                left: 0;
               // width: 100%;
               width:200px;
               margin-top: 50px;
               overflow-y: auto;
               height:100%;
            }
        }

        .layout-content {
            margin-left: 0;
            margin-top:100px;
        }
    }
}

@media (max-width: 640px) {
	.layout-wrapper {
		.layout-editor {
			.layout-editor-panel {
				width: 100%;
				right: -100%;
			}
		}
	}
}
*/
.layout-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  background-color: #2a3238;
  height: 100%;
  overflow-y: auto;
  z-index: 9999;
  transform: translateX(-100%);
}
.layout-sidebar.active {
  transform: translateX(0);
}
.layout-sidebar .layout-menu {
  padding: 0 1rem;
  overflow-y: auto;
  flex-grow: 1;
  border-right: 1px solid var(--surface-d);
}
.layout-sidebar .layout-menu .menu-items {
  padding: 0 0 1rem 0;
  display: flex;
  flex-direction: column;
  /*                        Dropdown option                       */
  /*

  .dropdown-content a:hover {background-color:#353c43;}

  .dropdown:hover .dropdown-content {display: block;}

  .dropdown:hover .dropdown-btn {background-color: #353c43;} // above 3 commented for drop down 
  /* Optional: Style the caret down icon */
  /*
     .active {
       background-color: green;
       color: white;
     }



     //Add an active class to the active dropdown button 
           .active {
               background-color: green;
               color: white;
           }*/
  /*                            Dropdown option                    */
}
.layout-sidebar .layout-menu .menu-items a {
  /* color: var(--text-color);
   display: block;
   padding: .5rem;
   border-radius: 3px;
   cursor: pointer;*/
  text-decoration: none;
  display: block;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 18px;
  padding-bottom: 18px;
  color: #d8d8d8;
  background-color: #2a3238;
  font-size: 14px;
  border-left: 4px solid transparent;
  border-bottom: 1px solid #23292e;
  /*   &:focus {
         z-index: 1;
         outline: 0 none;
         transition: box-shadow .2s;
         box-shadow: 0 0 0 0.2em $focusBorderColor;
     }


     &.router-link-exact-active {
         font-weight: 700;
     }

     */
}
.layout-sidebar .layout-menu .menu-items a i {
  margin-right: 12px;
  vertical-align: middle;
}
.layout-sidebar .layout-menu .menu-items a span {
  vertical-align: middle;
}
.layout-sidebar .layout-menu .menu-items a:hover {
  background-color: #353c43;
}
.layout-sidebar .layout-menu .menu-items a.active {
  background-color: #72add4;
  border-left-color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items a.active i {
  color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items a .p-tag {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn {
  text-decoration: none;
  display: block;
  padding-left: 10px;
  padding-right: 27px;
  padding-top: 18px;
  padding-bottom: 18px;
  color: #d8d8d8;
  background-color: #2a3238;
  font-size: 14px;
  border-left: 4px solid transparent;
  border-bottom: 1px solid #23292e;
  /*   &:focus {
         z-index: 1;
         outline: 0 none;
         transition: box-shadow .2s;
         box-shadow: 0 0 0 0.2em $focusBorderColor;
     }


     &.router-link-exact-active {
         font-weight: 700;
     }

     */
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn i {
  margin-right: 12px;
  vertical-align: middle;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn span {
  vertical-align: middle;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn:hover {
  background-color: #353c43;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn.active {
  background-color: #72add4;
  border-left-color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn.active i {
  color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items .dropdown-btn .p-tag {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.layout-sidebar .layout-menu .menu-items .dropdown {
  position: relative;
  display: inline-block;
}
.layout-sidebar .layout-menu .menu-items .dropdown-content {
  display: none;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.layout-sidebar .layout-menu .menu-items .dropdown-content a {
  color: #d8d8d8;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.layout-sidebar .layout-menu .menu-items .fa-caret-down {
  float: right;
  padding-left: 6px;
}
.layout-sidebar .layout-menu .menu-items .dropdown-container {
  display: none;
  background-color: #262626;
  padding-left: 8px;
  color: #d8d8d8;
}
.layout-sidebar .layout-menu .menu-items.menu-banner a {
  padding: 0;
}
.layout-sidebar .layout-menu .menu-items.menu-banner a:hover {
  background-color: #353c43;
}
.layout-sidebar .layout-menu .menu-items.menu-banner a:hover img {
  background-color: var(--surface-c);
}
.layout-sidebar .layout-menu .menu-items.menu-banner a:focus {
  box-shadow: none;
}
.layout-sidebar .layout-menu .menu-items div.active > a {
  font-weight: 700;
  background-color: #72add4;
  border-left-color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items div.active > a i {
  color: #007ad9;
}
.layout-sidebar .layout-menu .menu-items div.active ul {
  display: block;
}
.layout-sidebar .layout-menu .menu-items div ul {
  padding: 0.5rem 0;
  margin: 0;
  list-style-type: none;
}
.layout-sidebar .layout-menu .menu-items div ul a {
  font-size: 0.875rem;
  padding: 0.475rem 0.5rem 0.475rem 2rem;
}
.layout-sidebar .layout-sidebar-filter {
  padding: 1.25rem 1rem;
  border-bottom: 1px solid var(--surface-d);
  background-color: var(--surface-a);
}

/*
.layout-sidebar {
    position: fixed;
    left: 0;
    top: 70px; //60
    width: 200px;
    background-color: #2a3238;
    height: 100%;
    z-index: 9999;
    @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

    .layout-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            a {
                text-decoration: none;
                display: block;
                padding: 12px 24px;
                color: #d8d8d8;
                background-color: #2a3238;
                font-size: 16px;
                border-left: 4px solid transparent;
                border-bottom: 1px solid #23292e;

                i {
                    margin-right: 12px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                }

                &:hover {
                    background-color: #353c43;
                }

                &.active{
                   // background-color: #1d2327;
                   background-color: #72add4;
                    border-left-color: #007ad9;

                    i {
                        color: #007ad9;
                    }
                }
            }
        }
    }
}
*/
.layout-topbar {
  background: #40474b;
  padding: 0;
  height: 40px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid var(--surface-d);
  display: flex;
  align-items: center;
  padding: 0 35px;
}
.layout-topbar .menu-button {
  display: block;
  color: #d8d8d8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  transition: background-color 0.2s;
  cursor: pointer;
}
.layout-topbar .menu-button:hover {
  background-color: var(--surface-c);
}
.layout-topbar .menu-button i {
  font-size: 24px;
  line-height: inherit;
}
.layout-topbar .app-theme {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 39px;
  height: 39px;
}
.layout-topbar .app-theme img {
  width: 25px;
}
.layout-topbar .topbar-menu {
  list-style-type: none;
  margin: 100px;
  padding: 0;
  height: 100%;
  margin-left: auto;
  display: flex;
}
.layout-topbar .topbar-menu > li {
  height: 40px;
  line-height: 40px;
  color: white;
  font-size: 13px;
  display: block;
  text-align: left;
  padding: 0 10px 0 10px;
}
.layout-topbar .topbar-menu > li > a {
  text-decoration: none;
  color: var(--text-color);
  min-width: 120px;
  font-size: 14px;
  display: block;
  text-align: center;
  user-select: none;
  line-height: 38px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s;
  cursor: pointer;
}
.layout-topbar .topbar-menu > li > a:hover, .layout-topbar .topbar-menu > li > a:focus {
  border-bottom-color: var(--primary-color);
}
.layout-topbar .topbar-menu > li > a:focus {
  z-index: 1;
  outline: 0 none;
  transition: box-shadow 0.2s;
  box-shadow: inset 0 0 0 0.2em #BBDEFB;
}
.layout-topbar .topbar-menu > li.topbar-submenu {
  position: relative;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul {
  position: absolute;
  transform-origin: top;
  top: 70px;
  right: 0;
  z-index: 1;
  width: 275px;
  max-height: 400px;
  background-color: var(--surface-f);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  overflow: auto;
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  border-radius: 3px;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul > li {
  line-height: 1;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul > li.topbar-submenu-header {
  display: block;
  color: var(--text-color-secondary);
  font-weight: 600;
  user-select: none;
  padding: 1.5rem 0 1rem 0;
  font-size: 0.857rem;
  text-transform: uppercase;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul > li.topbar-submenu-header:first-child {
  padding-top: 1rem;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul a {
  text-decoration: none;
  color: var(--text-color);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul a:hover {
  background-color: var(--surface-c);
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul a span {
  margin-left: 0.5rem;
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul a i {
  font-size: 18px;
  color: var(--text-color-secondary);
}
.layout-topbar .topbar-menu > li.topbar-submenu > ul a img {
  width: 32px;
  margin-right: 0.5rem;
}
.layout-topbar .topbar-menu .dropdown-btntop {
  text-decoration: none;
  display: block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #d8d8d8;
  background-color: #2a3238;
  font-size: 13px;
  border-left: 4px solid transparent;
  border-bottom: 1px solid #23292e;
  /*   &:focus {
         z-index: 1;
         outline: 0 none;
         transition: box-shadow .2s;
         box-shadow: 0 0 0 0.2em $focusBorderColor;
     }


     &.router-link-exact-active {
         font-weight: 700;
     }

     */
}
.layout-topbar .topbar-menu .dropdown-btntop i {
  margin-right: 12px;
  vertical-align: middle;
}
.layout-topbar .topbar-menu .dropdown-btntop span {
  vertical-align: middle;
}
.layout-topbar .topbar-menu .dropdown-btntop:hover {
  background-color: #353c43;
}
.layout-topbar .topbar-menu .dropdown-btntop.active {
  background-color: #72add4;
  border-left-color: #007ad9;
}
.layout-topbar .topbar-menu .dropdown-btntop.active i {
  color: #007ad9;
}
.layout-topbar .topbar-menu .dropdown-btntop .p-tag {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.layout-topbar .topbar-menu .dropbtntop:hover, .layout-topbar .topbar-menu .dropbtn:focus {
  background-color: #2980B9;
}
.layout-topbar .topbar-menu .dropdowntop {
  position: relative;
  display: inline-block;
}
.layout-topbar .topbar-menu .dropdown-contenttop {
  display: none;
  position: fixed;
  background-color: #353c43;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.layout-topbar .topbar-menu .dropdown-contenttop a {
  color: #d8d8d8;
  text-decoration: none;
  display: block;
}
.layout-topbar .topbar-menu .dropdown-contenttop a:hover {
  background-color: #72add4;
}
.layout-topbar .topbar-menu .show {
  display: block;
}
.layout-topbar .topbar-menu .theme-badge {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 3px;
  color: #ffffff;
  font-weight: bold;
  font-size: 11px;
  position: relative;
  top: -1px;
}
.layout-topbar .topbar-menu .theme-badge.material {
  background: linear-gradient(to bottom, #2196F3, #2196F3);
}
.layout-topbar .topbar-menu .theme-badge.bootstrap {
  background: linear-gradient(to bottom, #563D7C, #966BD8);
}
.layout-topbar .topbar-menu .theme-badge.darkmode {
  background: linear-gradient(to bottom, #141d26, #5a6067);
}

.layout-navtopbar {
  background: #3a4246;
  padding: 10px;
  height: 30px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  border-bottom: 1px solid var(--surface-d);
  display: flex;
  align-items: center;
  padding: 0 35px;
  margin-top: -18px;
}
.layout-navtopbar .menu-button {
  display: block;
  color: #d8d8d8;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  transition: background-color 0.2s;
  cursor: pointer;
  /* i {
       font-size: 20px;
       padding-right: 5px;
       line-height: inherit;
   }*/
}
.layout-navtopbar .menu-button a {
  text-decoration: none;
  color: whitesmoke;
  min-width: 120px;
  font-size: 18px;
  display: block;
  text-align: center;
  user-select: none;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s;
  cursor: pointer;
}
.layout-navtopbar .menu-button a i {
  font-size: 24px;
  line-height: inherit;
  margin-left: 5px;
}
.layout-navtopbar .menu-button a span {
  vertical-align: middle;
  margin-left: 5px;
}
.layout-navtopbar .menu-button:hover {
  background-color: var(--surface-c);
}
.layout-navtopbar .app-theme {
  background-color: var(--primary-color);
  color: var(--primary-color-text);
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  width: 39px;
  height: 39px;
}
.layout-navtopbar .app-theme img {
  width: 25px;
}
.layout-navtopbar .topbar-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-left: auto;
  display: flex;
}
.layout-navtopbar .topbar-menu > li {
  height: 30px;
  line-height: 30px;
}
.layout-navtopbar .topbar-menu > li > a {
  text-decoration: none;
  color: whitesmoke;
  min-width: 120px;
  font-size: 14px;
  display: block;
  text-align: center;
  user-select: none;
  line-height: 28px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s;
  cursor: pointer;
  /*     &:focus {
           z-index: 1;
           outline: 0 none;
           transition: box-shadow .2s;
           box-shadow: 0 0 0 0.2em $focusBorderColor;
       }


       &.router-link-exact-active {
           font-weight: 700;
       }*/
}
.layout-navtopbar .topbar-menu > li > a i {
  margin-left: 12px;
  vertical-align: middle;
}
.layout-navtopbar .topbar-menu > li > a span {
  vertical-align: middle;
}
.layout-navtopbar .topbar-menu > li > a:hover {
  background-color: #353c43;
}
.layout-navtopbar .topbar-menu > li > a.active {
  background-color: #72add4;
  border-left-color: #007ad9;
}
.layout-navtopbar .topbar-menu > li > a.active i {
  color: #007ad9;
}
.layout-navtopbar .topbar-menu > li > a .p-tag {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.layout-navtopbar .topbar-menu > li > a:hover, .layout-navtopbar .topbar-menu > li > a:focus {
  border-bottom-color: var(--primary-color);
}
.layout-navtopbar .topbar-menu > li > a:focus {
  z-index: 1;
  outline: 0 none;
  transition: box-shadow 0.2s;
  box-shadow: inset 0 0 0 0.2em #BBDEFB;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu {
  position: relative;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul {
  position: absolute;
  transform-origin: top;
  top: 70px;
  right: 0;
  z-index: 1;
  width: 275px;
  max-height: 400px;
  background-color: var(--surface-f);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  overflow: auto;
  list-style-type: none;
  padding: 1rem;
  margin: 0;
  border-radius: 3px;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul > li {
  line-height: 1;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul > li.topbar-submenu-header {
  display: block;
  color: var(--text-color-secondary);
  font-weight: 600;
  user-select: none;
  padding: 1.5rem 0 1rem 0;
  font-size: 0.857rem;
  text-transform: uppercase;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul > li.topbar-submenu-header:first-child {
  padding-top: 1rem;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul a {
  text-decoration: none;
  color: var(--text-color);
  padding: 0.5rem;
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 3px;
  cursor: pointer;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul a:hover {
  background-color: var(--surface-c);
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul a span {
  margin-left: 0.5rem;
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul a i {
  font-size: 18px;
  color: var(--text-color-secondary);
}
.layout-navtopbar .topbar-menu > li.topbar-submenu > ul a img {
  width: 32px;
  margin-right: 0.5rem;
}
.layout-navtopbar .topbar-menu .theme-badge {
  padding: 2px 4px;
  vertical-align: middle;
  border-radius: 3px;
  color: #ffffff;
  font-weight: bold;
  font-size: 11px;
  position: relative;
  top: -1px;
}
.layout-navtopbar .topbar-menu .theme-badge.material {
  background: linear-gradient(to bottom, #2196F3, #2196F3);
}
.layout-navtopbar .topbar-menu .theme-badge.bootstrap {
  background: linear-gradient(to bottom, #563D7C, #966BD8);
}
.layout-navtopbar .topbar-menu .theme-badge.darkmode {
  background: linear-gradient(to bottom, #141d26, #5a6067);
}

.layout-footer {
  width: 100%;
  background-color: #343c41;
  padding-top: 7px;
  line-height: 10px;
  color: #d8d8d8;
  position: fixed;
  display: block;
  left: 0;
  bottom: 0;
  height: 80px;
}
.layout-footer .footer-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  height: 100%;
  margin-left: 24%;
  display: flex;
}
.layout-footer .footer-menu > li {
  height: 20px;
  line-height: 20px;
  border-right: 2px solid #c4c4c4;
  float: left;
  padding: 0 5px;
}
.layout-footer .footer-menu > li > a {
  text-decoration: none;
  color: whitesmoke;
  min-width: 120px;
  font-size: 12px;
  display: block;
  text-align: center;
  user-select: none;
  line-height: 20px;
  border-bottom: 2px solid transparent;
  transition: border-bottom-color 0.2s;
  cursor: pointer;
  /*    &.active{
          // background-color: #1d2327;
          background-color: #72add4;
           border-left-color: #007ad9;

           i {
               color: #007ad9;
           }
       }

  */
  /*&:hover, &:focus {
     /* border-bottom-color: var(--primary-color);
  }*/
}
.layout-footer .footer-menu > li > a i {
  margin-left: 12px;
  vertical-align: middle;
}
.layout-footer .footer-menu > li > a span {
  vertical-align: middle;
}
.layout-footer .footer-menu > li > a:hover {
  background-color: #353c43;
}
.layout-footer .footer-menu > li > a .p-tag {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.layout-footer .footer-menu > li > a:focus {
  z-index: 1;
  outline: 0 none;
  transition: box-shadow 0.2s;
  box-shadow: inset 0 0 0 0.2em #BBDEFB;
}
.layout-footer .footer-menu li:last-child {
  border-right: 0;
}

.layout-content {
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-bottom: 89px;
  padding-top: 10px;
}
.layout-content .content-section {
  padding: 2rem;
}
.layout-content .content-section.introduction {
  background-color: var(--surface-b);
  color: var(--text-color);
  padding-bottom: 0;
  display: flex;
  align-items: top;
  justify-content: space-between;
}
.layout-content .content-section.introduction .feature-intro h1 span {
  font-weight: 400;
  color: var(--text-color-secondary);
}
.layout-content .content-section.introduction .feature-intro p {
  margin: 0;
}
.layout-content .content-section.introduction .feature-intro a {
  text-decoration: none;
  color: #2196f3;
  font-weight: 600;
}
.layout-content .content-section.introduction .feature-intro a:hover {
  text-decoration: underline;
}
.layout-content .content-section.introduction app-demoActions {
  display: flex;
  justify-content: flex-end;
}
.layout-content .content-section.introduction app-demoActions .p-button-icon-only {
  padding: 0.5rem 0;
}
.layout-content .content-section.introduction .app-inputstyleswitch {
  margin-left: 2rem;
}
.layout-content .content-section.implementation {
  background-color: var(--surface-b);
  color: var(--text-color);
}
.layout-content .content-section.implementation + .documentation {
  padding-top: 0;
}
.layout-content .content-section.documentation {
  background-color: var(--surface-b);
  color: var(--text-color);
}
.layout-content .content-section.documentation li {
  line-height: 1.5;
}
.layout-content .content-section.documentation a {
  text-decoration: none;
  color: #2196f3;
  font-weight: 600;
}
.layout-content .content-section.documentation a:hover {
  text-decoration: underline;
}
.layout-content .content-section.documentation .doc-tablewrapper {
  margin: 1rem 0;
  overflow: auto;
}
.layout-content .content-section.documentation i:not([class~=pi]) {
  background-color: var(--surface-a);
  color: #2196f3;
  font-family: Monaco, courier, monospace;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 4px;
  letter-spacing: 0.5px;
  border-radius: 3px;
  font-weight: 600;
  margin: 0 2px;
}
.layout-content .content-section.documentation .p-tabview {
  background: transparent;
  border: 0 none;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav {
  border-radius: 0;
  padding: 0;
  border-bottom: 1px solid var(--surface-d);
  background-color: transparent;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li {
  margin-right: 0;
  border: 0 none;
  top: 1px;
  line-height: 1;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li a, .layout-content .content-section.documentation .p-tabview .p-tabview-nav li a:visited {
  color: var(--text-color-secondary);
  text-shadow: none;
  height: inherit;
  background-color: transparent;
  border: 0 none;
  border-bottom: 1px solid transparent;
  margin-bottom: -1px;
  transition: border-bottom-color 0.2s;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li a:focus, .layout-content .content-section.documentation .p-tabview .p-tabview-nav li a:visited:focus {
  outline: 0 none;
  transition: box-shadow 0.2s;
  box-shadow: 0 0 0 0.2rem #BBDEFB;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li a:hover, .layout-content .content-section.documentation .p-tabview .p-tabview-nav li a:visited:hover {
  background: transparent;
  text-decoration: none;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li.p-highlight a, .layout-content .content-section.documentation .p-tabview .p-tabview-nav li.p-highlight:hover a {
  background: transparent;
  color: var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover a {
  color: var(--text-color);
  border-bottom: 1px solid var(--primary-color);
}
.layout-content .content-section.documentation .p-tabview .p-tabview-nav .p-tabview-ink-bar {
  display: none;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-panels {
  background: transparent;
  border: 0 none;
  padding: 2rem 1rem;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-panels .btn-viewsource {
  display: inline-block;
  padding: 0.5rem 1rem;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-panels a {
  text-decoration: none;
  color: #2196f3;
  font-weight: 600;
}
.layout-content .content-section.documentation .p-tabview .p-tabview-panels a:hover {
  text-decoration: underline;
}
.layout-content .content-section .doc-table {
  border-collapse: collapse;
  width: 100%;
  background-color: var(--surface-a);
}
.layout-content .content-section .doc-table th {
  border-bottom: 1px solid var(--surface-d);
  padding: 1rem;
  text-align: left;
}
.layout-content .content-section .doc-table tbody td {
  padding: 1rem;
  border-bottom: 1px solid var(--surface-d);
}
.layout-content .content-section .doc-table tbody td:first-child {
  font-family: "Courier New", monospace;
  font-weight: bold;
  color: var(--text-color-secondary);
}
.layout-content .content-section .doc-table.browsers td:first-child {
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
}
.layout-content .card {
  background: var(--surface-e);
  padding: 2rem;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 2rem;
}
.layout-content .card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.layout-content .fluid-video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.layout-content .fluid-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card {
  border-radius: 3px;
  background: #ffffff;
  padding: 16px;
  margin-bottom: 16px;
}
.card.card-w-title {
  padding-bottom: 16px;
}
.card.no-gutter {
  margin-bottom: 0;
}
.card h1 {
  font-size: 20px;
  font-weight: 400;
  margin: 24px 0 12px 0;
}
.card h1:first-child {
  margin-top: 0px;
}
.card h2 {
  font-size: 18px;
  font-weight: 700;
}
.card h3 {
  font-size: 16px;
  font-weight: 700;
}
.card h4 {
  font-size: 14px;
  font-weight: 700;
}

.layout-mask {
  background-color: rgba(0, 0, 0, 0.1);
}
.layout-mask.layout-mask-active {
  z-index: 998;
  width: 100%;
  height: 100%;
  position: fixed;
  margin-top: -80px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 0.5s;
}

@media screen and (max-width: 1024px) {
  .layout-topbar {
    height: 120px;
  }
  .layout-topbar .app-theme {
    margin-left: 0;
    margin-right: 23px;
  }
  .layout-topbar .topbar-menu {
    background-color: var(--surface-a);
    width: 100%;
    height: 20px;
    margin: 0;
    border-top: 1px solid var(--surface-d);
    font-size: 10px;
  }
  .layout-topbar .topbar-menu > li {
    height: 20px;
    line-height: 30px;
    width: 25%;
  }
  .layout-topbar .topbar-menu > li > a {
    padding-bottom: 0;
    height: 40px;
    line-height: 38px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    min-width: auto;
  }
  .layout-topbar .topbar-menu > li.topbar-submenu > ul {
    top: 40px;
  }

  .layout-footer {
    height: 80px;
    line-height: 10px;
  }
  .layout-footer .footer-menu {
    background-color: var(--surface-a);
    width: 100%;
    height: 20px;
    margin: 0;
    border-top: 1px solid var(--surface-d);
    font-size: 10px;
  }
  .layout-footer .footer-menu > li {
    height: 20px;
    line-height: 30px;
    width: 25%;
  }
  .layout-footer .footer-menu > li > a {
    padding-bottom: 0;
    height: 20px;
    line-height: 20px;
    width: 100%;
    font-size: 10px;
    font-weight: 300;
    min-width: auto;
  }

  .layout-mask.layout-mask-active {
    margin-top: -170px;
  }
}
@media screen and (max-width: 1000px) {
  .layout-footer {
    height: 100px;
  }
}
@media screen and (max-width: 960px) {
  .layout-wrapper .layout-news {
    margin-left: 0;
  }
  .layout-wrapper .layout-news .layout-news-button {
    display: none;
  }

  /*  .layout-navtopbar {
        margin-left: 0;
    }*/
  .layout-topbar {
    margin-left: 0;
    height: 100px;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
  }
  .layout-topbar .menu-button {
    display: block;
  }
  .layout-topbar .logo img {
    width: 150px;
  }
  .layout-topbar .app-theme {
    margin-left: 0;
    margin-right: 23px;
  }
  .layout-topbar .topbar-menu {
    background-color: var(--surface-a);
    height: 20px;
    margin: 0;
    border-top: 5px solid var(--surface-d);
    margin-left: 654px;
    margin-top: -100px;
    font-size: 10px;
  }
  .layout-topbar .topbar-menu > li {
    height: 10px;
    line-height: 20px;
    width: 100%;
    font-size: 10px;
    padding-bottom: 0;
    padding: 0 40px 0 40px;
  }
  .layout-topbar .topbar-menu > li > a {
    padding-bottom: 0;
    height: 40px;
    line-height: 38px;
    width: 100%;
    font-size: 12px;
    font-weight: 600;
    min-width: auto;
  }
  .layout-topbar .topbar-menu > li.topbar-submenu > ul {
    top: 40px;
  }

  .layout-sidebar {
    transform: translateX(-100%);
    z-index: 999;
  }
  .layout-sidebar.active {
    transform: translateX(0);
  }

  .layout-content {
    margin-left: 10px;
  }
  .layout-content .content-section.introduction {
    flex-direction: column;
  }
  .layout-content .content-section.introduction .app-inputstyleswitch {
    margin-top: 1.5rem;
    margin-left: 0;
  }
  .layout-content .video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .layout-content .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .layout-footer {
    height: 90px;
    line-height: 10px;
  }
  .layout-footer .footer-menu {
    background-color: var(--surface-a);
    width: 100%;
    height: 20px;
    margin: 0;
    border-top: 1px solid var(--surface-d);
    font-size: 10px;
  }
  .layout-footer .footer-menu > li {
    height: 20px;
    line-height: 30px;
    width: 25%;
  }
  .layout-footer .footer-menu > li > a {
    padding-bottom: 0;
    height: 20px;
    line-height: 20px;
    width: 100%;
    font-size: 10px;
    font-weight: 200;
    min-width: auto;
  }

  .layout-mask {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .layout-mask.layout-mask-active {
    z-index: 998;
    width: 100%;
    height: 100%;
    position: fixed;
    margin-top: -160px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: background-color 0.5s;
  }

  .home .introduction > div {
    width: 100%;
  }
  .home .features > div {
    width: 100%;
  }
  .home .whouses > div {
    width: 100%;
  }
  .home .prosupport > div {
    width: 100%;
  }

  .layout-config .layout-config-button {
    left: auto;
    right: -52px;
  }
  .layout-config.layout-config-active {
    width: 100%;
  }

  .blocked-scroll {
    overflow: hidden;
  }
}
@media screen and (max-width: 860px) {
  .layout-topbar .topbar-menu {
    margin-left: 550px;
    margin-top: -100px;
    font-size: 1px;
  }
  .layout-topbar .topbar-menu > li {
    font-size: 10px;
    padding: 0 35px 0 35px;
  }

  .layout-footer {
    height: 100px;
    line-height: 10px;
  }
}
@media screen and (max-width: 760px) {
  .layout-topbar .topbar-menu {
    margin-left: 380px;
    margin-top: -120px;
    font-size: 1px;
  }
  .layout-topbar .topbar-menu > li {
    font-size: 10px;
    padding: 0 10px 0 10px;
  }

  .layout-footer {
    height: 110px;
    line-height: 6px;
  }
}
@media screen and (max-width: 640px) {
  .layout-wrapper.layout-news-active .topbar-menu > li.topbar-submenu > ul {
    top: 180px;
  }

  .layout-topbar .topbar-menu > li.topbar-submenu {
    position: static;
  }
  .layout-topbar .topbar-menu > li.topbar-submenu > ul {
    top: 110px;
    position: fixed;
    right: auto;
    left: 0;
    width: 100vw;
  }
}
@media screen and (max-width: 540px) {
  .layout-topbar .topbar-menu {
    margin-left: 380px;
    margin-top: -120px;
  }
}
@media screen and (max-width: 440px) {
  .layout-topbar .topbar-menu {
    margin-left: 280px;
    margin-top: -120px;
  }
  .layout-topbar .topbar-menu > li {
    font-size: 5px;
    padding: 0 25px 0 25px;
  }
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container-xl, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar .container,
.navbar .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  /*@include font-size($navbar-brand-font-size);*/
  line-height: inherit;
  white-space: nowrap;
  font-style: normal;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-style: normal;
  color: whitesmoke;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  font-style: normal;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container-xl, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container-xl, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container,
.navbar-expand > .container-fluid {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(52, 58, 64, 0.9);
  /*font-size: large;*/
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(52, 58, 64, 0.9);
  background-color: #637add;
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(52, 58, 64, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(52, 58, 64, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(52, 58, 64, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(52, 58, 64, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(52, 58, 64, 0.5);
  border-color: rgba(52, 58, 64, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(52, 58, 64, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"));
}
.navbar-light .navbar-text {
  color: rgba(52, 58, 64, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(52, 58, 64, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(52, 58, 64, 0.9);
}

.navbar-dark .navbar-brand {
  color: #FFFFFF;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #FFFFFF;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFFFFF;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"));
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #FFFFFF;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #FFFFFF;
}