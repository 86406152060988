@import '../../webcore/webcore-variables';
@import '../../blue-oval-theme/webcore/variables';
@import '../../blue-oval-theme/_variables';

@mixin shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    box-shadow: $value;
}


//nav

$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;

$nav-divider-color:                 $gray-200 !default;
$nav-divider-margin-y:              $spacer / 2 !default;


// Navbar

$navbar-padding-y:                  $spacer / 2 !default;
$navbar-padding-x:                  $spacer !default;

$navbar-nav-link-padding-x:         .5rem !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;

$navbar-dark-color:                 rgba($white, .5) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;

$navbar-light-color:                rgba($black, .5) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;

$navbar-light-brand-color:                $navbar-light-active-color !default;
$navbar-light-brand-hover-color:          $navbar-light-active-color !default;
$navbar-dark-brand-color:                 $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color !default;





body {
    font-family: $fontFamily;
    color: #333333;
    font-size: 13px;
    background-color: #f3f3f3;
    padding: 0;
    margin: 0;

    &.layout-scroll-block {
        overflow: hidden;
    }
}




/*.layout-wrapper {
    .layout-topbar {
        background: #252d32;
        height: 70px; //60
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 9999;
        @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

        .layout-topbar-content {
            padding: 10px 20px;
            position: relative;

            .topbar-logo {
                height: 40px;
                vertical-align: middle;
            }

            .menu-btn {
                display: none;
                color: #d8d8d8;
                font-size: 24px;
                vertical-align: middle;
                margin-right: 20px;
                margin-left: 20px;

                i {
                    vertical-align: middle;
                }
            }

            .topbar-link {
                color: #d8d8d8;
                position: absolute;
                right: 32px;
                top: 0;
                display: block;
                height: 60px;
                line-height: 60px;
                font-size: 16px;
                padding: 0 16px;
                text-decoration: none;

                &:hover {
                    background-color: #3a4246;
                }
            }
        }
    }

    .layout-sidebar {
        position: fixed;
        left: 0;
        top: 70px; //60
        width: 200px;
        background-color: #2a3238;
        height: 100%;
        z-index: 9999;
        @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

        .layout-menu {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    text-decoration: none;
                    display: block;
                    padding: 12px 24px;
                    color: #d8d8d8;
                    background-color: #2a3238;
                    font-size: 16px;
                    border-left: 4px solid transparent;
                    border-bottom: 1px solid #23292e;

                    i {
                        margin-right: 12px;
                        vertical-align: middle;
                    }

                    span {
                        vertical-align: middle;
                    }

                    &:hover {
                        background-color: #353c43;
                    }

                    &.active{
                       // background-color: #1d2327;
                       background-color: #72add4;
                        border-left-color: #007ad9;

                        i {
                            color: #007ad9;
                        }
                    }
                }
            }
        }
    }

    .layout-editor {
        .layout-editor-button {
            display: block;
            position: fixed;
            width: 52px;
            height: 52px;
            line-height: 52px;
            background-color: #037ad9;
            text-align: center;
            top: 120px;
            right: 0;
            z-index: 1000000;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            transition: background-color .2s;
            box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 12px 17px 2px rgba(0, 0, 0, 0.14);
            cursor: pointer;
            transition: background-color .3s;
            z-index: 9999;

            &:hover {
                background-color: #0261ad;
            }

            i {
                line-height: inherit;
                font-size: 32px;
                color: #ffffff;
            }
        }

        .layout-editor-panel {
            position: fixed;
            width: 600px;
            height: 100%;
            right: -600px;
            top: 0;
            z-index: 10000;
            transition: right .3s;
            background-color: #2a3238;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.16);
            padding: 1em 0 2em 0;

            &.layout-editor-panel-active {
                right: 0;
            }

            .layout-editor-close-button {
                display: inline-block;
                width: 52px;
                height: 52px;
                line-height: 52px;
                text-align: center;
                border-radius: 50%;
                margin-bottom: 1em;
                margin-right: 1em;
                transition: background-color .3s;
                cursor: pointer;

                &:hover {
                    background-color: #4b4b4b;
                }

                i {
                    color: #037ad9;
                    line-height: inherit;
                    font-size: 32px;
                }
            }

            h1 {
                display: inline-block;
                color: #ffffff;
                margin: .5em 1em;
                font-size: 24px;
            }

            button {
                display: block;
                width: 100%;
                border-radius: 0;

                .ui-button-text {
                    padding: 1em;
                }

                .ui-button-icon-left {
                    font-size: 24px;
                }
            }

            .close-button-container {
                text-align: right;
            }

			.highlights-container {
				height: calc(100% - 87px);
				overflow: auto;
				background-color: #2a3238;
				color: #ffffff;
				padding: 1em;

				pre {
					font-size: 16px;
					font-family: Courier New,Courier,Lucida Sans Typewriter,Lucida Typewriter,monospace;
					white-space: pre-wrap;
                    overflow-wrap: break-word;
                    color: #ffa94d;

					&:focus {
						outline: 0 none;
					}

					.scss-variable {
						color: #4dabf7;
					}

					.scss-comment {
						color: #38d9a9;
					}
				}
			}
        }
    }

    .layout-content {
        margin-left: 200px;
        padding: 84px 24px 24px 24px;
    }

    .layout-footer {
        width: 100%;
        background-color: #343c41;
        padding: 16px;
        padding-left: 266px;
        color: #d8d8d8;
    }
}

.card {
    border-radius: 3px;
    background: #ffffff;
    padding: 16px;
    margin-bottom: 16px;

    &.card-w-title {
        padding-bottom: 16px;
    }

    &.no-gutter {
        margin-bottom: 0;
    }

    h1 {
        font-size: 20px;
        font-weight: 400;
        margin: 24px 0 12px 0;

        &:first-child {
            margin-top: 0px;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: 700;
    }

    h3 {
        font-size: 16px;
        font-weight: 700;
    }

    h4 {
        font-size: 14px;
        font-weight: 700;
    }
}


@media (max-width: 1024px) {
    .layout-wrapper {
        .layout-topbar {
            height: 100px;//
            .layout-topbar-content {
                .topbar-logo {
                    height: 28px;
                    margin-top: 6px;
                    margin-left: 100px;//
                }

                .menu-btn {
                    display: inline-block;
                }
            }
        }

        .layout-sidebar {
            left: -250px;

            &.layout-sidebar-active {
                left: 0;
               // width: 100%;
               width:200px;
               margin-top: 50px;
               overflow-y: auto;
               height:100%;
            }
        }

        .layout-content {
            margin-left: 0;
            margin-top:100px;
        }
    }
}

@media (max-width: 640px) {
	.layout-wrapper {
		.layout-editor {
			.layout-editor-panel {
				width: 100%;
				right: -100%;
			}
		}
	}
}
*/




$linkColor: #2196f3;
$focusBorderColor:#BBDEFB;

@mixin focused() {
    outline: 0 none;
    transition: box-shadow .2s;
    box-shadow: 0 0 0 0.2rem $focusBorderColor;
}









.layout-sidebar {


     position: fixed;
    left: 0;
    top:0;
    //top: 70px; //60
    width: 250px;
    background-color: #2a3238;
    height: 100%;
    overflow-y:auto;
   z-index: 9999;
  //  @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

   // position: fixed;
    //left: 0;
    //top: 0;
    //height: 100%;
    //background-color: var(--surface-a);
    //width: 250px;
   // border-right: 1px solid var(--surface-d);
   // user-select: none;
    //transition: transform .4s cubic-bezier(.05,.74,.2,.99);
    transform: translateX(-100%);
  //  display: flex;
   // flex-direction: column;

   &.active {
    transform: translateX(0);
    //top:150px
    
}
    
    .layout-menu {
        padding: 0 1rem;
        overflow-y: auto;
        flex-grow: 1;
        border-right: 1px solid var(--surface-d);

        .menu-items {
            padding: 0 0 1rem 0;
            display: flex;
            flex-direction: column;
           

            a {
               /* color: var(--text-color);
                display: block;
                padding: .5rem;
                border-radius: 3px;
                cursor: pointer;*/


                text-decoration: none;
                display: block;
               // padding: 12px 15px;
               padding-left:10px;
               padding-right:10px;
               padding-top:18px;
               padding-bottom:18px;
               
                color: #d8d8d8;
                background-color: #2a3238;
                font-size: 14px;
               border-left: 4px solid transparent;
                border-bottom: 1px solid #23292e;



                
                i {
                    margin-right: 12px;
                    vertical-align: middle;
                }



                span {
                    vertical-align: middle;
                   // margin-left:5px;
                }

                &:hover {
                    //background-color: var(--surface-c);


                    background-color: #353c43;
                }

             /*   &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: 0 0 0 0.2em $focusBorderColor;
                }


                &.router-link-exact-active {
                    font-weight: 700;
                }

                */

                &.active{
                    // background-color: #1d2327;
                    background-color: #72add4;
                     border-left-color: #007ad9;
                     
 
                     i {
                         color: #007ad9;
                     }
                 }






             

                .p-tag {
                    padding-top:.125rem;
                    padding-bottom: .125rem;
                }
            }


            .dropdown-btn{


                text-decoration: none;
                display: block;
               // padding: 12px 15px;
               padding-left:10px;
               padding-right:27px;
               padding-top:18px;
               padding-bottom:18px;
               
                color: #d8d8d8;
                background-color: #2a3238;
                font-size: 14px;
               border-left: 4px solid transparent;
                border-bottom: 1px solid #23292e;



                
                i {
                    margin-right: 12px;
                    vertical-align: middle;
                }



                span {
                    vertical-align: middle;
                   // margin-left:5px;
                }

                &:hover {
                    //background-color: var(--surface-c);


                    background-color: #353c43;
                }

             /*   &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: 0 0 0 0.2em $focusBorderColor;
                }


                &.router-link-exact-active {
                    font-weight: 700;
                }

                */

                &.active{
                    // background-color: #1d2327;
                    background-color: #72add4;
                     border-left-color: #007ad9;
                     
 
                     i {
                         color: #007ad9;
                     }
                 }






             

                .p-tag {
                    padding-top:.125rem;
                    padding-bottom: .125rem;
                }





            }


/*                        Dropdown option                       */



            .dropdown {
                position: relative;
                display: inline-block;
              }
              
              .dropdown-content {
                display: none;
                //position: absolute;
                background-color: #f1f1f1;
               min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                z-index: 1;
              }
              
              .dropdown-content a {
                color: #d8d8d8; ;
                padding: 12px 16px;
                text-decoration: none;
                display: block;
              }
              /*
              
              .dropdown-content a:hover {background-color:#353c43;}
              
              .dropdown:hover .dropdown-content {display: block;}
              
              .dropdown:hover .dropdown-btn {background-color: #353c43;} // above 3 commented for drop down 
              /* Optional: Style the caret down icon */
              .fa-caret-down {
                float: right;
                //padding-right: 8px;
                padding-left: 6px;
              }

// dropdwon button ------------------------------------------------
           /*
              .active {
                background-color: green;
                color: white;
              }



              //Add an active class to the active dropdown button 
                    .active {
                        background-color: green;
                        color: white;
                    }*/ //not used
                    
                    // Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content 
                    .dropdown-container {
                        display: none;
                        background-color: #262626;
                        padding-left: 8px;
                        color: #d8d8d8; ;
               
                    }


  //dropdown button -------------------------------------------------------                  


/*                            Dropdown option                    */






            &.menu-banner {
                a {
                    padding: 0;

                    &:hover {
                       // background-color: transparent;
                       background-color: #353c43;

                        img {
                            background-color: var(--surface-c);
                        }
                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            div {
                &.active {
                    > a {
                        font-weight: 700;
                        background-color: #72add4;
                        border-left-color: #007ad9;
    
    
                        i {
                            color: #007ad9;
                        }
                    }

                    ul {
                        display: block;
                    }
                }

                ul {
                    padding: .5rem 0;
                    margin: 0;
                    list-style-type: none;

                    a {
                        font-size: .875rem;
                        padding: .475rem .5rem .475rem 2rem;
                    }
                }
            }
        }
    }

    .layout-sidebar-filter {
        padding: 1.25rem 1rem;
        border-bottom: 1px solid var(--surface-d);
        background-color: var(--surface-a);
    }


    


}




/*
.layout-sidebar {
    position: fixed;
    left: 0;
    top: 70px; //60
    width: 200px;
    background-color: #2a3238;
    height: 100%;
    z-index: 9999;
    @include shadow(0 0 16px 0 rgba(0, 0, 0, 0.16));

    .layout-menu {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            a {
                text-decoration: none;
                display: block;
                padding: 12px 24px;
                color: #d8d8d8;
                background-color: #2a3238;
                font-size: 16px;
                border-left: 4px solid transparent;
                border-bottom: 1px solid #23292e;

                i {
                    margin-right: 12px;
                    vertical-align: middle;
                }

                span {
                    vertical-align: middle;
                }

                &:hover {
                    background-color: #353c43;
                }

                &.active{
                   // background-color: #1d2327;
                   background-color: #72add4;
                    border-left-color: #007ad9;

                    i {
                        color: #007ad9;
                    }
                }
            }
        }
    }
}
*/


.layout-topbar {
   // background-color: var(--surface-a);
   background: #40474b;
    padding: 0;
    height: 40px;
    box-shadow: 0 0 4px rgba(0,0,0,0.25);
    border-bottom: 1px solid var(--surface-d);
    display: flex;
    align-items: center;
    padding: 0 35px;
 
 // margin-left: 200px;

    .menu-button {
        display: block;
        //color: var(--text-color);
        color: #d8d8d8;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        transition: background-color .2s;
        cursor: pointer;

        &:hover {
            background-color: var(--surface-c);
        }

        i {
            font-size: 24px;
            line-height: inherit;
        }
    }

    .app-theme {
        background-color: var(--primary-color);
        color: var(--primary-color-text);
        padding: .5rem;
        border-radius: 4px;
        box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
        width: 39px;
        height: 39px;
    
        img {
            width: 25px;
        }
    }


    
    .topbar-menu {
        list-style-type: none;
        margin: 100px;
        padding: 0;
        height: 100%;
        margin-left: auto;
        display: flex;

        > li {
            height: 40px;
            line-height:40px;
            color:white;
            font-size: 13px;
            display: block;
            text-align: left;
            padding:0 10px 0 10px; 

            > a {
                text-decoration: none;
                color: var(--text-color);
                min-width: 120px;
                font-size: 14px;
                display: block;
                text-align: center;
                user-select: none;
                line-height: 38px;
                border-bottom: 2px solid transparent;
                transition: border-bottom-color .2s;
                cursor: pointer;

                &:hover, &:focus {
                    border-bottom-color: var(--primary-color);
                }

                &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                }
            }

            &.topbar-submenu {
                position: relative;

                > ul {
                    position: absolute;
                    transform-origin: top;
                    top: 70px;
                    right: 0;
                    z-index: 1;
                    width: 275px;
                    max-height: 400px;
                    background-color: var(--surface-f);
                    box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
                    overflow: auto;
                    list-style-type: none;
                    padding: 1rem;
                    margin: 0;
                    border-radius: 3px;

                    > li {
                        line-height: 1;

                        &.topbar-submenu-header {
                            display: block;
                            color: var(--text-color-secondary);
                            font-weight: 600;
                            user-select: none;
                            padding: 1.5rem 0 1rem 0;
                            font-size: 0.857rem;
                            text-transform: uppercase;
                
                            &:first-child {
                                padding-top: 1rem;
                            }
                        }
                    }

                    a {
                        text-decoration: none;
                        color: var(--text-color);
                        padding: .5rem;
                        display: flex;
                        align-items: center;
                        user-select: none;
                        border-radius: 3px;
                        cursor: pointer;

                        &:hover {
                            background-color: var(--surface-c);
                        }

                        span {
                            margin-left: .5rem;
                        }

                        i {
                            font-size: 18px;
                            color: var(--text-color-secondary);
                        }

                        img {
                            width: 32px;
                            margin-right: .5rem;
                        }
                    }
                }
            }
        }


        .dropdown-btntop
        {
           

            text-decoration: none;
            display: block;
           // padding: 12px 15px;
           padding-left:5px;
           padding-right:5px;
           padding-top:10px;
           padding-bottom:10px;
           
            color: #d8d8d8;
            background-color: #2a3238;
            font-size: 13px;
           border-left: 4px solid transparent;
            border-bottom: 1px solid #23292e;



            
            i {
                margin-right: 12px;
                vertical-align: middle;
            }



            span {
                vertical-align: middle;
               // margin-left:5px;
            }

            &:hover {
                //background-color: var(--surface-c);


                background-color: #353c43;
            }

         /*   &:focus {
                z-index: 1;
                outline: 0 none;
                transition: box-shadow .2s;
                box-shadow: 0 0 0 0.2em $focusBorderColor;
            }


            &.router-link-exact-active {
                font-weight: 700;
            }

            */

            &.active{
                // background-color: #1d2327;
                background-color: #72add4;
                 border-left-color: #007ad9;
                 

                 i {
                     color: #007ad9;
                 }
             }






         

            .p-tag {
                padding-top:.125rem;
                padding-bottom: .125rem;
            }
        }




        .dropbtntop:hover, .dropbtn:focus {
            background-color: #2980B9;
          }
          
          .dropdowntop {
            position: relative;
            display: inline-block;
          }
          
          .dropdown-contenttop {
            display: none;
            position: fixed;
            background-color:#353c43;
            min-width: 160px;
            overflow: auto;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
          }
          
          .dropdown-contenttop a {
            color:  #d8d8d8;
           
            text-decoration: none;
            display: block;
          }
          
          .dropdown-contenttop a:hover {background-color: #72add4;}
          
          .show {display: block;}




        .theme-badge {
            padding: 2px 4px;
            vertical-align: middle;
            border-radius: 3px;
            color: #ffffff;
            font-weight: bold;
            font-size: 11px;
            position: relative;
            top: -1px;
        }

        .theme-badge.material {
            background: linear-gradient(to bottom, #2196F3, #2196F3);
        }

        .theme-badge.bootstrap {
            background: linear-gradient(to bottom, #563D7C, #966BD8);
        }

        .theme-badge.darkmode {
            background: linear-gradient(to bottom, #141d26, #5a6067);
        }
    }
}





//*********************************************************



.layout-navtopbar {
    // background-color: var(--surface-a);
    background: #3a4246;
     padding: 10px;
     height: 30px;
     box-shadow: 0 0 4px rgba(0,0,0,0.25);
     border-bottom: 1px solid var(--surface-d);
     display: flex;
     align-items: center;
     padding: 0 35px;
     margin-top:-18px;
    
     //top:1px;
    // position: fixed;
   // overflow: auto;
    //float: left;
    //width:100%;
  // margin-left: 200px;
  // color:white;
 
  
 
     .menu-button {
         display: block;
         //color: var(--text-color);
         color: #d8d8d8;
         width: 30px;
         height: 30px;
         line-height: 30px;
         text-align: center;
         transition: background-color .2s;
         cursor: pointer;


         a {
            text-decoration: none;
          
           // color: var(--text-color);
           color:whitesmoke;
            min-width: 120px;
            font-size: 18px;
            display: block;
            text-align: center;
            user-select: none;
            line-height: 30px;
            border-bottom: 2px solid transparent;
            transition: border-bottom-color .2s;
            cursor: pointer;


            i {
                font-size: 24px;
                line-height: inherit;
                margin-left:5px;
            }
            span {
                vertical-align: middle;
               margin-left:5px;
            }

        }

 
         &:hover {
             background-color: var(--surface-c);
         }
 
        /* i {
             font-size: 20px;
             padding-right: 5px;
             line-height: inherit;
         }*/
     }
 
     .app-theme {
         background-color: var(--primary-color);
         color: var(--primary-color-text);
         padding: .5rem;
         border-radius: 4px;
         box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
         width: 39px;
         height: 39px;
     
         img {
             width: 25px;
         }
     }
 
 
     
     .topbar-menu {
        list-style-type: none;
        margin: 0;
         padding: 0;
        height: 100%;
        margin-left: auto;
         display: flex;
         //float:left;
 
         > li {
             height: 30px;
             line-height: 30px;
 
             > a {
                 text-decoration: none;
               
                // color: var(--text-color);
                color:whitesmoke;
                 min-width: 120px;
                 font-size: 14px;
                 display: block;
                 text-align: center;
                 user-select: none;
                 line-height: 28px;
                 border-bottom: 2px solid transparent;
                 transition: border-bottom-color .2s;
                 cursor: pointer;


                 i {
                    margin-left: 12px;
                    vertical-align: middle;
                }



                span {
                    vertical-align: middle;
                   // margin-left:5px;
                }

                &:hover {
                    //background-color: var(--surface-c);


                    background-color: #353c43;
                }

           /*     &:focus {
                    z-index: 1;
                    outline: 0 none;
                    transition: box-shadow .2s;
                    box-shadow: 0 0 0 0.2em $focusBorderColor;
                }


                &.router-link-exact-active {
                    font-weight: 700;
                }*/

                

                &.active{
                    // background-color: #1d2327;
                    background-color: #72add4;
                     border-left-color: #007ad9;
 
                     i {
                         color: #007ad9;
                     }
                 }


             

                .p-tag {
                    padding-top:.125rem;
                    padding-bottom: .125rem;
                }
 
                 &:hover, &:focus {
                     border-bottom-color: var(--primary-color);
                 }
 
               &:focus {
                     z-index: 1;
                     outline: 0 none;
                     transition: box-shadow .2s;
                     box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                 }
             }
 
             &.topbar-submenu {
                 position: relative;
 
                 > ul {
                     position: absolute;
                     transform-origin: top;
                     top: 70px;
                     right: 0;
                     z-index: 1;
                     width: 275px;
                     max-height: 400px;
                     background-color: var(--surface-f);
                     box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12);
                     overflow: auto;
                     list-style-type: none;
                     padding: 1rem;
                     margin: 0;
                     border-radius: 3px;
 
                     > li {
                         line-height: 1;
 
                         &.topbar-submenu-header {
                             display: block;
                             color: var(--text-color-secondary);
                             font-weight: 600;
                             user-select: none;
                             padding: 1.5rem 0 1rem 0;
                             font-size: 0.857rem;
                             text-transform: uppercase;
                 
                             &:first-child {
                                 padding-top: 1rem;
                             }
                         }
                     }
 
                     a {
                         text-decoration: none;
                         color: var(--text-color);
                         padding: .5rem;
                         display: flex;
                         align-items: center;
                         user-select: none;
                         border-radius: 3px;
                         cursor: pointer;
 
                         &:hover {
                             background-color: var(--surface-c);
                         }
 
                         span {
                             margin-left: .5rem;
                         }
 
                         i {
                             font-size: 18px;
                             color: var(--text-color-secondary);
                         }
 
                         img {
                             width: 32px;
                             margin-right: .5rem;
                         }
                     }
                 }
             }
         }
 
         .theme-badge {
             padding: 2px 4px;
             vertical-align: middle;
             border-radius: 3px;
             color: #ffffff;
             font-weight: bold;
             font-size: 11px;
             position: relative;
             top: -1px;
         }
 
         .theme-badge.material {
             background: linear-gradient(to bottom, #2196F3, #2196F3);
         }
 
         .theme-badge.bootstrap {
             background: linear-gradient(to bottom, #563D7C, #966BD8);
         }
 
         .theme-badge.darkmode {
             background: linear-gradient(to bottom, #141d26, #5a6067);
         }
     }
 }


 //**************************************************************************

.layout-footer {
        width: 100%;
        background-color: #343c41;
        //padding: 22px;
        padding-top: 7px;
        line-height: 10px;
        //padding-left: 266px;
        color: #d8d8d8;
        position: fixed;
        display: block;
        left: 0;
        bottom: 0;
        height:80px; // Replace with the height your footer should be
       
         
     .footer-menu {
        list-style-type: none;
       margin: 0;
        padding: 0;
        height: 100%;
        margin-left:24%;
         display: flex;
         //float:left;
 
         > li {
             height: 20px;
             line-height: 20px;
             border-right: 2px solid #c4c4c4;
            
            //display: inline-block;
            float:left;
            padding: 0 5px;
            
             > a {
                 text-decoration: none;
               
                // color: var(--text-color);
                color:whitesmoke;
                 min-width: 120px;
                 font-size: 12px;
                 display: block;
                 text-align: center;
                 user-select: none;
                 line-height: 20px;
                 border-bottom: 2px solid transparent;
                 transition: border-bottom-color .2s;
                 cursor: pointer;


                 i {
                    margin-left: 12px;
                    vertical-align: middle;
                }



                span {
                    vertical-align: middle;
                   // margin-left:5px;
                }

                &:hover {
                    //background-color: var(--surface-c);


                    background-color: #353c43;
                }

                

            /*    &.active{
                    // background-color: #1d2327;
                    background-color: #72add4;
                     border-left-color: #007ad9;
 
                     i {
                         color: #007ad9;
                     }
                 }

            */
             

                .p-tag {
                    padding-top:.125rem;
                    padding-bottom: .125rem;
                }
 
                 /*&:hover, &:focus {
                    /* border-bottom-color: var(--primary-color);
                 }*/
 
               &:focus {
                     z-index: 1;
                     outline: 0 none;
                     transition: box-shadow .2s;
                     box-shadow: inset 0 0 0 0.2em $focusBorderColor;
                 }
             }
 
            
         }
 
         
     }
     .footer-menu li:last-child {
        border-right:0;
     }
    
    }

  .layout-content {
      //  margin-left: 220px;
       margin-top:3px;
       margin-left:10px;
       margin-right:10px;
       margin-bottom:10px;
       padding-bottom:89px;
       padding-top: 10px;

    
      .content-section {
            padding: 2rem;
    
            &.introduction {
                background-color: var(--surface-b);
                color: var(--text-color);
                padding-bottom: 0;
                display: flex;
                align-items: top;
                justify-content: space-between;
    
                .feature-intro {
                    h1 {
                        span {
                            font-weight: 400;
                            color: var(--text-color-secondary);
                        }
                    }
    
                    p {
                        margin: 0;
                    }
    
                    a {
                        text-decoration: none;
                        color: $linkColor;
                        font-weight: 600;
        
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
    
                app-demoActions {
                    display: flex;
                    justify-content: flex-end;
                    
                    .p-button-icon-only {
                        padding: .5rem 0;
                    }
                }
    
                .app-inputstyleswitch {
                    margin-left: 2rem;
                }
            }
    
            &.implementation {
                background-color: var(--surface-b);
                color: var(--text-color);
    
                +.documentation {
                    padding-top: 0;
                }
            }
    
            &.documentation {
                background-color: var(--surface-b);
                color: var(--text-color);
    
                li {
                    line-height: 1.5;
                }
    
                a {
                    text-decoration: none;
                    color: $linkColor;
                    font-weight: 600;
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
    
                .doc-tablewrapper {
                    margin: 1rem 0;
                    overflow: auto;
                }
    
                i:not([class~="pi"]) {
                    background-color: var(--surface-a);
                    color: #2196f3;
                    font-family: Monaco, courier, monospace;
                    font-style: normal;
                    font-size: 12px;
                    font-weight: 500;
                    padding: 2px 4px;
                    letter-spacing: .5px;
                    border-radius: 3px;
                    font-weight: 600;
                    margin: 0 2px;
                }
    
                .p-tabview {
                    background: transparent;
                    border: 0 none;
    
                    .p-tabview-nav {
                        border-radius: 0;
                        padding: 0;
                        border-bottom: 1px solid var(--surface-d);
                        background-color: transparent;
    
                        li {
                            margin-right: 0;
                            border: 0 none;
                            top: 1px;
                            line-height: 1;
    
                            a, a:visited {
                                color: var(--text-color-secondary);
                                text-shadow: none;
                                height: inherit;
                                background-color: transparent;
                                border: 0 none;
                                border-bottom: 1px solid transparent;
                                margin-bottom: -1px;
                                transition: border-bottom-color .2s;
    
                                &:focus {
                                    @include focused();
                                }
    
                                &:hover {
                                    background: transparent;
                                    text-decoration: none;
                                }
                            }
    
                            &.p-highlight a,
                            &.p-highlight:hover a {
                                background: transparent;
                                color: var(--primary-color);
                                border-bottom: 1px solid var(--primary-color);
                            }
    
                            &:not(.p-highlight):not(.p-disabled):hover a {
                                color: var(--text-color);
                                border-bottom: 1px solid var(--primary-color);
                            }
                        }
    
                        .p-tabview-ink-bar {
                            display: none;
                        }
                    }
    
                    .p-tabview-panels {
                        background: transparent;
                        border: 0 none;
                        padding: 2rem 1rem;
    
                        .btn-viewsource {
                            display: inline-block;
                            padding: .5rem 1rem;
                        }
    
                        a {
                            text-decoration: none;
                            color: $linkColor;
                            font-weight: 600;
    
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
      .doc-table {
                border-collapse: collapse;
                width: 100%;
                background-color: var(--surface-a);
    
                th {
                    border-bottom: 1px solid var(--surface-d);
                    padding: 1rem;
                    text-align: left;
                }
    
                tbody {
                    td {
                        padding: 1rem;
                        border-bottom: 1px solid var(--surface-d);
    
                        &:first-child {
                            font-family: "Courier New", monospace;
                            font-weight: bold;
                            color: var(--text-color-secondary);
                        }
                    }
                }
    
                &.browsers {
                    td {
                        &:first-child {
                            font-family: var(--font-family);
                            font-weight: normal;
                            color: var(--text-color);
                        }
                    }
                }
            }
        }
    
       .card {
            background: var(--surface-e);
            padding: 2rem;
            box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
            border-radius: 4px;
            margin-bottom: 2rem;
            
            .card-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    
        .fluid-video {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
    
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }



    .card {
        border-radius: 3px;
        background: #ffffff;
        padding: 16px;
        margin-bottom: 16px;
    
        &.card-w-title {
            padding-bottom: 16px;
        }
    
        &.no-gutter {
            margin-bottom: 0;
        }
    
        h1 {
            font-size: 20px;
            font-weight: 400;
            margin: 24px 0 12px 0;
    
            &:first-child {
                margin-top: 0px;
            }
        }
    
        h2 {
            font-size: 18px;
            font-weight: 700;
        }
    
        h3 {
            font-size: 16px;
            font-weight: 700;
        }
    
        h4 {
            font-size: 14px;
            font-weight: 700;
        }
    }


    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
           // top: 125px;
           margin-top:-80px;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color .5s;
        }
    }

    



    @media screen and (max-width: 1024px) {
    
        .layout-topbar {

            height:120px;
           
    
            .app-theme {
                margin-left: 0;
                margin-right: 23px;
            }
    
            .topbar-menu {
               background-color: var(--surface-a);
                width: 100%;
                height: 20px;
                margin: 0;
                border-top: 1px solid var(--surface-d);
                //margin-right:auto;
                
                
                font-size:10px;
    
                > li {
                    height: 20px;
                    line-height: 30px;
                    width: 25%;
                    //color:black;
                   // font-size:20px;
                   
                    
    
                    > a {
                        padding-bottom: 0;
                        height: 40px;
                        line-height: 38px;
                        width: 100%;
                        font-size: 12px;
                        font-weight: 600;
                        min-width: auto;
                    }
    
                    &.topbar-submenu > ul {
                        top: 40px;
                    }
                }
            }
        }

        .layout-footer{

            height:80px;
            line-height: 10px;
            .footer-menu{
                background-color: var(--surface-a);
                width: 100%;
                height: 20px;
                margin: 0;
                border-top: 1px solid var(--surface-d);
                //margin-right:auto;
                
                
                font-size:10px;
    
                > li {
                    height: 20px;
                    line-height: 30px;
                    width: 25%;
                    //color:black;
                   // font-size:20px;
                   
                    
    
                    > a {
                        padding-bottom: 0;
                        height: 20px;
                        line-height: 20px;
                        width: 100%;
                        font-size: 10px;
                        font-weight: 300;
                        min-width: auto;
                    }
    
                 
                }
            }
        }


        .layout-mask{
            &.layout-mask-active {
                
               margin-top:-170px;
              
                
            }

        }
    }


    @media screen and (max-width: 1000px) {
        .layout-footer{

            height:100px;
        }
    }

@media screen and (max-width: 960px) {
    .layout-wrapper {
        .layout-news {
            margin-left: 0;
            .layout-news-button {
                display: none;
            }
        }
    }

    
//******************************
  /*  .layout-navtopbar {
        margin-left: 0;
    }*/

    .layout-topbar {
        margin-left: 0;
        height: 100px;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        //padding-left:50px;

        .menu-button {
            display: block;
        }

        .logo {
            img{
                width: 150px;
            }
        }

        .app-theme {
            margin-left: 0;
            margin-right: 23px;
        }

        .topbar-menu {
            background-color: var(--surface-a);
           // width: 100%;
            height: 20px;
            margin: 0;
            border-top: 5px solid var(--surface-d);
            margin-left:654px;
            margin-top:-100px;
            font-size:10px;

            > li {
                height: 10px;
                line-height: 20px;
               width: 100%;
                //color:black;
               font-size:10px;
               padding-bottom: 0;
              // display: block;
              // text-align: center;
               padding:0 40px 0 40px;

                > a {
                    padding-bottom: 0;
                    height: 40px;
                    line-height: 38px;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 600;
                    min-width: auto;
                }

                &.topbar-submenu > ul {
                    top: 40px;
                }
            }
        }
    }

    .layout-sidebar {
        transform: translateX(-100%);
        z-index: 999;
        

        &.active {
            transform: translateX(0);
            //top:150px
            
        }
    }

    .layout-content {
        margin-left: 10px;

        .content-section {
            &.introduction {
                flex-direction: column;

                .app-inputstyleswitch {
                    margin-top: 1.5rem;
                    margin-left: 0;
                }
            }
        }

        .video-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .layout-footer{

        height:90px;
        line-height: 10px;
        .footer-menu{
            background-color: var(--surface-a);
            width: 100%;
            height: 20px;
            margin: 0;
            border-top: 1px solid var(--surface-d);
            //margin-right:auto;
            
            
            font-size:10px;

            > li {
                height: 20px;
                line-height: 30px;
                width: 25%;
                //color:black;
               // font-size:20px;
               
                

                > a {
                    padding-bottom: 0;
                    height: 20px;
                    line-height: 20px;
                    width: 100%;
                    font-size: 10px;
                    font-weight: 200;
                    min-width: auto;
                }

             
            }
        }
    }

    .layout-mask {
        background-color: rgba(0, 0, 0, 0.1);

        &.layout-mask-active {
            z-index: 998;
            width: 100%;
            height: 100%;
            position: fixed;
           // top: 125px;
           margin-top:-160px;
            left: 0;
            background-color: rgba(0, 0, 0, 0.4);
            transition: background-color .5s;
        }
    }

    .home {
        .introduction > div {
            width: 100%;
        }

        .features > div {
            width: 100%;
        }

        .whouses > div {
            width: 100%;
        }

        .prosupport > div {
            width: 100%;
        }
    }

    .layout-config {
        .layout-config-button {
            left: auto;
            right: -52px;
        }

        &.layout-config-active {
            width: 100%;
        }
    }

    .blocked-scroll {
        overflow: hidden;
    }
}


@media screen and (max-width: 860px) {
    .layout-topbar {
        .topbar-menu {
            margin-left:550px;
            margin-top:-100px;
            font-size:1px;
            > li {

                 font-size:10px;
                 padding:0 35px 0 35px;

            }

        }
    }
    .layout-footer{

        height:100px;
        line-height: 10px;
    }
}


@media screen and (max-width: 760px) {
    .layout-topbar {
        .topbar-menu {
            margin-left:380px;
            margin-top:-120px;
            font-size:1px;
            > li {

                 font-size:10px;
                 
                 padding:0 10px 0 10px;

            }

        }
    }
    .layout-footer{

        height:110px;
        line-height: 6px;
    }
}

@media screen and (max-width: 640px) {
    .layout-wrapper.layout-news-active {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    > ul  {
                        top: 180px;
                    }
                }
            }
        }
    }

    .layout-topbar {
        .topbar-menu {
            > li {
                &.topbar-submenu {
                    position: static;

                    > ul {
                        top: 110px;
                        position: fixed;
                        right: auto;
                        left: 0;
                        width: 100vw;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 540px) {


    .layout-topbar {
        .topbar-menu {
            margin-left:380px;
           margin-top:-120px;
        }
    }

}

@media screen and (max-width: 440px) {


    .layout-topbar {
        .topbar-menu {
            margin-left:280px;
           margin-top:-120px;


           > li {

            font-size:5px;
            padding:0 25px 0 25px;

       }
        }
    }

}








//*****************************************************************




// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes


// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.navbar {
    position: relative;
    display: flex;
    
    flex-wrap: wrap; // allow us to do the line break for collapsing content
    align-items: center;
    justify-content: space-between; // space out brand from logo
    padding: $navbar-padding-y $navbar-padding-x;

    //margin-left: 200px;
  
    // Because flex properties aren't inherited, we need to redeclare these first
    // few properties so that content nested within behave properly.
    %container-flex-properties {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  
    .container,
    .container-fluid {
      @extend %container-flex-properties;
    }
  
    @each $breakpoint, $container-max-width in $container-max-widths {
      > .container#{breakpoint-infix($breakpoint, $container-max-widths)} {
        @extend %container-flex-properties;
      }
    }
  }
  
  
  // Navbar brand
  //
  // Used for brand, project, or site names.
  
  .navbar-brand {
    display: inline-block;
    padding-top: $navbar-brand-padding-y;
    padding-bottom: $navbar-brand-padding-y;
    margin-right: $navbar-padding-x;
    /*@include font-size($navbar-brand-font-size);*/
    line-height: inherit;
    white-space: nowrap;
    font-style: normal;

    
  
    @include hover-focus() {
      text-decoration: none;
    }
  }
  
  
  // Navbar nav
  //
  // Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).
  
  .navbar-nav {
    display: flex;
    flex-direction: row; // cannot use `inherit` to get the `.navbar`s value
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    font-style: normal;

    color: whitesmoke;
  
  
    .nav-link {
      padding-right: 0;
      padding-left: 0;
    }
  
    .dropdown-menu {
      position: static;
      float: none;
    }
  }
  
  
  // Navbar text
  //
  //
  
  .navbar-text {
    display: inline-block;
    font-style: normal;
    padding-top: $nav-link-padding-y;
    padding-bottom: $nav-link-padding-y;
    
  }
  
  
  // Responsive navbar
  //
  // Custom styles for responsive collapsing and toggling of navbar contents.
  // Powered by the collapse Bootstrap JavaScript plugin.
  
  // When collapsed, prevent the toggleable navbar contents from appearing in
  // the default flexbox row orientation. Requires the use of `flex-wrap: wrap`
  // on the `.navbar` parent.
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    // For always expanded or extra full navbars, ensure content aligns itself
    // properly vertically. Can be easily overridden with flex utilities.
    align-items: center;
  }
  
  // Button for toggling the navbar when in its collapsed state
  .navbar-toggler {
    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    //@include font-size($navbar-toggler-font-size);
    line-height: 1;
    background-color: transparent; // remove default button style
    border: $border-width solid transparent; // remove default button style
   
  
    @include hover-focus() {
      text-decoration: none;
    }
  }
  
  // Keep as a separate element so folks can easily override it with another icon
  // or image file as needed.
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  // Generate series of `.navbar-expand-*` responsive classes for configuring
  // where your navbar collapses.
  .navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);
  
      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          %container-navbar-expand-#{$breakpoint} {
            padding-right: 0;
            padding-left: 0;
          }
  
          > .container,
          > .container-fluid {
            @extend %container-navbar-expand-#{$breakpoint};
          }
  
          @each $size, $container-max-width in $container-max-widths {
            > .container#{breakpoint-infix($size, $container-max-widths)} {
              @extend %container-navbar-expand-#{$breakpoint};
            }
          }
        }
  
        @include media-breakpoint-up($next) {
          flex-flow: row nowrap;
          justify-content: flex-start;
  
          .navbar-nav {
            flex-direction: row;
  
            .dropdown-menu {
              position: absolute;
            }
  
            .nav-link {
              padding-right: $navbar-nav-link-padding-x;
              padding-left: $navbar-nav-link-padding-x;
            }
          }
  
          // For nesting containers, have to redeclare for alignment purposes
          %container-nesting-#{$breakpoint} {
            flex-wrap: nowrap;
          }
  
          > .container,
          > .container-fluid {
            @extend %container-nesting-#{$breakpoint};
          }
  
          @each $size, $container-max-width in $container-max-widths {
            > .container#{breakpoint-infix($size, $container-max-widths)} {
              @extend %container-nesting-#{$breakpoint};
            }
          }
  
          .navbar-collapse {
            display: flex !important; // stylelint-disable-line declaration-no-important
  
            // Changes flex-bases to auto because of an IE10 bug
            flex-basis: auto;
          }
  
          .navbar-toggler {
            display: none;
          }
        }
      }
    }
  }
  
  
  // Navbar themes
  //
  // Styles for switching between navbars with light or dark background.
  
  // Dark links against a light background
  .navbar-light {
    .navbar-brand {
      color: $navbar-light-brand-color;
      /*font-size: large;*/
  
      @include hover-focus() {
        color: $navbar-light-brand-hover-color;
        background-color: rgb(99, 122, 221);
  
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $navbar-light-color;
        
        @include hover-focus() {
          color: $navbar-light-hover-color;
        }
  
        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }
  
      .show > .nav-link,
      .active > .nav-link,
     .nav-link.show,
      .nav-link.active {
        color: $navbar-light-active-color;
        //background-color:  rgb(99, 122, 221) ;
  
      }
  
    }
  
    .navbar-toggler {
      color: $navbar-light-color;
      border-color: $navbar-light-toggler-border-color;
    }
  
    .navbar-toggler-icon {
      background-image: escape-svg($navbar-light-toggler-icon-bg);
    }
  
    .navbar-text {
      color: $navbar-light-color;
      a {
        color: $navbar-light-active-color;
         
        @include hover-focus() {
          color: $navbar-light-active-color;
        }
      }
    }
  }
  
  // White links against a dark background
  .navbar-dark {
    .navbar-brand {
      color: $navbar-dark-brand-color;
  
      @include hover-focus() {
        color: $navbar-dark-brand-hover-color;
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $navbar-dark-color;
  
        @include hover-focus() {
          color: $navbar-dark-hover-color;
        }
  
        &.disabled {
          color: $navbar-dark-disabled-color;
        }
      }
  
      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $navbar-dark-active-color;
      }
    }
  
    .navbar-toggler {
      color: $navbar-dark-color;
      border-color: $navbar-dark-toggler-border-color;
    }
  
    .navbar-toggler-icon {
      background-image: escape-svg($navbar-dark-toggler-icon-bg);
    }
  
    .navbar-text {
      color: $navbar-dark-color;
      a {
        color: $navbar-dark-active-color;
  
        @include hover-focus() {
          color: $navbar-dark-active-color;
        }
      }
    }
  }
  



