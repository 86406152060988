.ui-tabmenu {
    border:  none;
    margin-top:-15px;
    //height:2px;
    margin-bottom: -10px;
    
    .ui-tabmenu-nav {
        padding: 0;
        background: $tabsNavBgColor;
       // margin-top: -50px;
        //margin-left:30px;
       // margin-top: 30px;
       // border-bottom: $panelHeaderBorder;
      // height: 2px;

        .ui-tabmenuitem {
            position: static;
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            margin-right: $tabHeaderSpacing;
            margin-left:9px;
            margin-top: 3px;
            margin-bottom: 1px;
            @include transition($panelHeaderTransition);

            .ui-menuitem-link {
                color: $panelHeaderTextColor;
                padding: $panelHeaderPadding;
                @include transition($panelHeaderTransition);

                .ui-menuitem-text {
                    color: $panelHeaderTextColor;
                    margin-right: $inlineSpacing;
                }

                .ui-menuitem-icon {
                    color: $panelHeaderIconColor;
                    margin-right: $inlineSpacing;
                }

                &:focus {
                    @include focused();
                }
            }

            &:not(.ui-state-active):not(.ui-state-disabled):hover {
                background-color: $panelHeaderHoverBgColor;
                border: $panelHeaderHoverBorder;

                .ui-menuitem-link {
                    .ui-menuitem-text {
                        color: $panelHeaderHoverTextColor;
                    }

                    .ui-menuitem-icon {
                        color: $panelHeaderIconHoverColor;
                    }
                }
            }

            &.ui-state-active {
                background-color: $panelHeaderActiveBgColor;
                border: $panelHeaderActiveBorder;

                .ui-menuitem-link {
                    .ui-menuitem-text {
                        color: $panelHeaderActiveTextColor;
                    }

                    .ui-menuitem-icon {
                        color: $panelHeaderActiveIconColor;
                    }
                }
            }
        }
    }
}
