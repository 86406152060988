/* override PrimeNG theme font family */
$fontFamilyDefault: "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontFamily: $fontFamilyDefault;
$fontFamilyFordAntenna: FordAntenna, Arial, Helvetica, sans-serif;
$fontFamilyFordAntennaLight: FordAntennaLight, Arial, Helvetica, sans-serif;
$fontFamilyFordAntennaCond: FordAntennaCond, Arial, Helvetica, sans-serif;

/*Font paths with obfuscated names from WebType*/

// note: due to scss import syntax, these urls deliberately do not match the location of this file.
// instead, they need to be formed as a url for where the fonts.scss file is imported.
$FontDirectory: "webcore/fonts/";

$FordAntennaGUID: $FontDirectory + "Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8";
$FordAntennaBoldGUID: $FontDirectory + "Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca";
$FordAntennaRegularItalicGUID: $FontDirectory + "Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44";
$FordAntennaBoldItalicGUID: $FontDirectory + "Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba";

$FordAntennaMediumGUID: $FontDirectory + "Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094";

$FordAntennaLightGUID: $FontDirectory + "Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5";
$FordAntennaLightItalicGUID: $FontDirectory + "Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853";

$FordAntennaCondensedGUID: $FontDirectory + "Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd";
$FordAntennaCondensedBoldGUID: $FontDirectory + "Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963";
$FordAntennaCondensedRegularItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09";
$FordAntennaCondensedBoldItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9";

$FordAntennaCondensedMediumGUID: $FontDirectory + "Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8";
$FordAntennaCondensedMediumItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224";

$WOFF2Postfix: "-5.woff2";
$WOFFPostfix: "-3.woff";
$TTFPostfix: "-1.ttf";

/*Font Properties*/

$font-style-normal : normal;
$font-style-italic: italic;
$font-weight-normal: normal;
$font-weight-bold: bold;

/* Color Palette
-------------------------------------------------- */
/*ui component Colors coming from the WCAG 2.0 AA Accessibility Compliance guide from GTB*/


/* Backgrounds */
/* Blue */
$navy: #0C1218;
$ninety-percent-navy: #24292F;
$ford-blue: #092A46;
//$CTA-dark-cyan: #0276B3; defined below
//$body-color:grey;
//$body-bg:grey;

/* Gray */
$light-grey-background-one: #F7F7F7;
$light-grey-background-two: #FAFAFA;
$white: #FFFFFF;

/* Text and Icons */
/* CTA Colors */
$CTA-dark-cyan: #0276B3;
$CTA-light-cyan: #37A9E8;
$CTA-icon-cyan: #2096CD;

/* Text Dark */
$navy: #0C1218;
$charcoal: #333333;
$grey: #555555


/* Text Light */;
$light-grey: #A7A7A7;
$white: #FFFFFF;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #343a40 !default;
/*$black:    #000 !default;*/

$grays: () !default;
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);
$border-width:                1px !default;
$border-color:                $gray-300 !default;
$border-radius:               .25rem !default;
$border-radius-lg:            .3rem !default;
$border-radius-sm:            .2rem !default;
$body-bg:                 $white !default;
$body-color:                $gray-900 !default;
$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;
$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;
$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;
$btn-border-radius:           $border-radius !default;
$btn-border-radius-lg:        $border-radius-lg !default;
$btn-border-radius-sm:        $border-radius-sm !default;



/* Alerts */
$dark-alert-red: #D92E14;
$light-alert-red: #FF5724;

$dark-alert-orange: #B85500;
$light-alert-orange: #C88310;

$dark-alert-green: #15830B;
$light-alert-green: #3FA51C;

/*Responsive*/
$app-header-font-transition: font-size .25s ease-in;
$app-header-width-transition: max-width .25s ease-in;
$app-header-margin-transition: margin-top .25s ease-in;
