main,
#app-content {
    width: 100%;
    padding: 0 .25em;
    //padding: 84px 24px 24px 24px;
    //margin-left: 200px;
    flex: 1 0 auto;
    @media screen and (min-width: $breakpoint-sm){
        padding: 0 1em;
    }
}
