.ui-toast {
    .ui-toast-message {
        @include shadow($toastShadow);
        /*margin: $toastMessageMargin;*/


        padding: 0.75rem 0.75rem;
        margin: 0;
        border-radius: 6px;
        font-size:10px;
        height:70px;
        width:fit-content;

        &.ui-toast-message-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;

            .ui-toast-close-icon {
                color: $infoMessageTextColor;
            }
        }

        &.ui-toast-message-success {
           /* background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;*/

            background: #e4f8f0;
  border: solid #1ea97c;
  border-width: 1px;
  color: #1ea97c;
  font-size:12px;

            .ui-toast-close-icon {
              /*  color: $successMessageIconColor;*/
              color: #1ea97c;
              font-size:15px;
            }
        }

        &.ui-toast-message-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;

            .ui-toast-close-icon {
                color: $warnMessageTextColor;
            }
        }

        &.ui-toast-message-error {
          /*  background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;*/


            background: #ffe7e6;
            border: solid #ff5757;
            border-width: 1px;
            color: #ff5757;
            font-size:12px;

            .ui-toast-close-icon {
                /*color: $errorMessageTextColor;*/
                color: #ff5757;
                font-size:15px;
            }
        }
    }
}
